import elevateSwarovskiBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-1.png";
import elevateSwarovskiBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-2.png";
import elevateSwarovskiBannerBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-poster-1.jpg";
import elevateSwarovskiBannerBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-poster-2.jpg";
import elevateSwarovskiBannerBabyBlue3 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-poster-3.jpg";
import elevateSwarovskiBannerBabyBlue4 from "../../assets/img/products/products/Single Product/ELEVATE SWAROVSKI/elevate-swarovski-poster-4.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - Artisan Pro - Red.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-elevate.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-elevatepro.jpg";

const elevateSwarovski = [
  {
    id: "elevate-swarovski-for-airpods-pro",
    name: "Elevate x Swarovski \n for AirPods Pro",
    device: "AirPods Gen 1/2  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [],
    selectedColor: "",
    images: [elevateSwarovskiBabyBlue1, elevateSwarovskiBabyBlue2],
    description:
      "Elevate x Swarovski Series brings the brilliant crystals from the widely recognized brand Swarovski to the AirPods accessory market. In collaboration with popular artists, KeyBudz developed a unique line of cases that feature current trending designs with Swarovski crystals embedded onto a crystal clear case. \n\n Elevate x Swarovski Series features a protective clear case with a Rose Gold carabiner attachment, adding the perfect touch of character to any AirPods charging case.",
    note: "",
    banners: [
      elevateSwarovskiBannerBabyBlue1,
      elevateSwarovskiBannerBabyBlue2,
      elevateSwarovskiBannerBabyBlue3,
      elevateSwarovskiBannerBabyBlue4,
    ],
    posters: [],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      "elevate-series-airpods-pro-case-pink",
      "elevate-series-airpods-pro-case-yellow",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-series-airpods-pro-case-lavender",
      },
    },
  },
];

export default elevateSwarovski;

import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "./Icon";
import styles from "./ButtonBorder.module.scss";

const ButtonBorder = ({ text, onClick, className, icon, arrowDown, showWhite, colorWhite }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={cx(styles.button, colorWhite && styles.colorWhite, className)}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
      {icon && (
        <CSSTransition
          mountOnEnter
          unmountOnExit
          in={!!icon}
          timeout={200}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
          }}
        >
          <Icon className="ml-3" showWhite={isHovered ? !showWhite : showWhite} icon={icon} arrowDown={arrowDown} />
        </CSSTransition>
      )}
    </button>
  );
};

ButtonBorder.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
};

ButtonBorder.defaultProps = {
  className: "",
  icon: "",
};

export default ButtonBorder;

import airCareImg1 from "../../assets/img/products/products/Single Product/AirCare/aircare-1.png";
import airCareImg2 from "../../assets/img/products/products/Single Product/AirCare/aircare-2.png";
import airCareImg3 from "../../assets/img/products/products/Single Product/AirCare/aircare-3.png";
import airCareImg4 from "../../assets/img/products/products/Single Product/AirCare/aircare-4.png";
import airCareBanner1 from "../../assets/img/products/products/Single Product/AirCare/aircare-banner-1.jpg";
import airCareBanner2 from "../../assets/img/products/products/Single Product/AirCare/aircare-banner-2.jpg";
import airCareBanner3 from "../../assets/img/products/products/Single Product/AirCare/aircare-banner-3.jpg";
import airCarePoster1 from "../../assets/img/products/products/Single Product/AirCare/aircare-poster-1.jpg";
import airCarePoster2 from "../../assets/img/products/products/Single Product/AirCare/aircare-poster-2.jpg";
import airCarePoster3 from "../../assets/img/products/products/Single Product/AirCare/aircare-poster-3.jpg";
import airCareInside1 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-1.png";
import airCareInside2 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-2.png";
import airCareInside3 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-3.png";
import airCareInside4 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-4.png";
import airCareInside5 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-5.png";
import airCareInside6 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-6.png";
import airCareInside7 from "../../assets/img/products/products/Single Product/AirCare/aircare-inside-7.png";
import airCareBottomBanner from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-airstrapz.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-hybridshell.jpg";

const aircare = [
  {
    id: "artisan-airpods-cleaning-kit",
    name: "AirCare \n AirPods Cleaning Kit",
    device: "AirPods",
    category: "AirPods Essentials",
    series: "",
    colors: [],
    selectedColor: "",
    images: [airCareImg1, airCareImg2, airCareImg3, airCareImg4],
    description:
      "Easily remove dirt, debris and ear wax build up on your AirPods and AirPods Pro. The AirCare Cleaning Kit provides you the perfect set of tools to sanitize and maintain the integrity of your AirPods. \n\n The complete cleaning solution to maximize the life of your AirPods.",
    note: "",
    banners: [airCareBanner1, airCareBanner2, airCareBanner3],
    posters: [airCarePoster1, airCarePoster2, airCarePoster3],
    insideImages: [
      airCareInside1,
      airCareInside2,
      airCareInside3,
      airCareInside4,
      airCareInside5,
      airCareInside6,
      airCareInside7,
    ],
    relatedProducts: [
      "earbuddyz-for-airpods-pro-white",
      "earbuddyz-for-airpods-gen-1-2-white",
      "earbuddyz-for-airpods-gen-1-2-black",
    ],
    bottomBanner: { img: airCareBottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airstrapz-for-airpods-pink",
      },
      next: {
        img: nextCategory,
        url: "/products/hybridshell-series-airpods-pro-case-cobalt-blue",
      },
    },
  },
];

export default aircare;

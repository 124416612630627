// Cobalt Bue
import hybridshellAirPodsProCobaltBlue1 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-1.png";
import hybridshellAirPodsProCobaltBlue2 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-2.png";
import hybridshellAirPodsProCobaltBlue3 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-3.png";
import hybridshellAirPodsProCobaltBlue4 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-4.png";
import hybridshellBannerCobaltBlue1 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-banner-1.jpg";
import hybridshellBannerCobaltBlue2 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-banner-2.jpg";
import hybridshellBannerCobaltBlue3 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-banner-3.jpg";
import hybridshellPosterCobaltBlue1 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-poster-1.jpg";
import hybridshellPosterCobaltBlue2 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-poster-2.jpg";
import hybridshellPosterCobaltBlue3 from "../../assets/img/products/products/Single Product/Hybridshell/Cobalt Blue/hbs-cbb-poster-3.jpg";
import bottomBannerCobaltBlue from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-aircare.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-hybridshellkeychain.jpg";

// Black
import hybridshellAirPodsProBlack1 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-1.png";
import hybridshellAirPodsProBlack2 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-2.png";
import hybridshellAirPodsProBlack3 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-3.png";
import hybridshellAirPodsProBlack4 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-4.png";
import hybridshellBannerBlack1 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-banner-1.jpg";
import hybridshellBannerBlack2 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-banner-2.jpg";
import hybridshellBannerBlack3 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-banner-3.jpg";
import hybridshellPosterBlack1 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-poster-1.jpg";
import hybridshellPosterBlack2 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-poster-2.jpg";
import hybridshellPosterBlack3 from "../../assets/img/products/products/Single Product/Hybridshell/Black/hbs-blk-poster-3.jpg";

// Pink
import hybridshellAirPodsProPink1 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-1.png";
import hybridshellAirPodsProPink2 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-2.png";
import hybridshellAirPodsProPink3 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-3.png";
import hybridshellAirPodsProPink4 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-4.png";
import hybridshellBannerPink1 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-banner-1.jpg";
import hybridshellBannerPink2 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-banner-2.jpg";
import hybridshellBannerPink3 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-banner-3.jpg";
import hybridshellPosterPink1 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-poster-1.jpg";
import hybridshellPosterPink2 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-poster-2.jpg";
import hybridshellPosterPink3 from "../../assets/img/products/products/Single Product/Hybridshell/Blush Pink/hbs-bpk-poster-3.jpg";

const hybridshell = [
  {
    id: "hybridshell-series-airpods-pro-case-cobalt-blue",
    name: "HybridShell Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Hybridshell Series",
    colors: [
      {
        color: "#202020",
        url: "hybridshell-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "hybridshell-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#FFBFBF",
        url: "hybridshell-series-airpods-pro-case-pink",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [
      hybridshellAirPodsProCobaltBlue1,
      hybridshellAirPodsProCobaltBlue2,
      hybridshellAirPodsProCobaltBlue3,
      hybridshellAirPodsProCobaltBlue4,
    ],
    description:
      "The HybridShell Series features the tough protection benefits of a polycarbonate hard shell and a proprietary dust resistant nano coating with a soft liquid silicone core. The two-piece design utilizes Restickable Secure Adhesive (RSA) in the lid to provide an ultra secure lid while the body is precisely engineered for a snug fit. \n\n The Stealth Button on the rear of the HybridShell Series case allows for intuitive access to the setup button on the rear of the AirPods charging case. The HybridShell Series Keychain case offers a convenient way to clip and carry your AirPods on the go.",
    note: "",
    banners: [hybridshellBannerCobaltBlue1, hybridshellBannerCobaltBlue2, hybridshellBannerCobaltBlue3],
    posters: [hybridshellPosterCobaltBlue1, hybridshellPosterCobaltBlue2, hybridshellPosterCobaltBlue3],
    relatedProducts: [
      "hybridshell-series-airpods-pro-case-black",
      "hybridshell-series-airpods-pro-case-pink",
      "hybridshell-series-airpods-pro-case-keychain-cobalt-blue",
    ],
    bottomBanner: { img: bottomBannerCobaltBlue, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
      next: {
        img: nextCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
    },
  },
  {
    id: "hybridshell-series-airpods-pro-case-black",
    name: "HybridShell Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Hybridshell Series",
    colors: [
      {
        color: "#202020",
        url: "hybridshell-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "hybridshell-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#FFBFBF",
        url: "hybridshell-series-airpods-pro-case-pink",
      },
    ],
    selectedColor: "#202020",
    images: [
      hybridshellAirPodsProBlack1,
      hybridshellAirPodsProBlack2,
      hybridshellAirPodsProBlack3,
      hybridshellAirPodsProBlack4,
    ],
    description:
      "The HybridShell Series features the tough protection benefits of a polycarbonate hard shell and a proprietary dust resistant nano coating with a soft liquid silicone core. The two-piece design utilizes Restickable Secure Adhesive (RSA) in the lid to provide an ultra secure lid while the body is precisely engineered for a snug fit. \n\n The Stealth Button on the rear of the HybridShell Series case allows for intuitive access to the setup button on the rear of the AirPods charging case. The HybridShell Series Keychain case offers a convenient way to clip and carry your AirPods on the go.",
    note: "",
    banners: [hybridshellBannerBlack1, hybridshellBannerBlack2, hybridshellBannerBlack3],
    posters: [hybridshellPosterBlack1, hybridshellPosterBlack2, hybridshellPosterBlack3],
    relatedProducts: [
      "hybridshell-series-airpods-pro-case-cobalt-blue",
      "hybridshell-series-airpods-pro-case-pink",
      "hybridshell-series-airpods-pro-case-keychain-cobalt-blue",
    ],
    bottomBanner: { img: bottomBannerCobaltBlue, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
      next: {
        img: nextCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
    },
  },
  {
    id: "hybridshell-series-airpods-pro-case-pink",
    name: "HybridShell Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Hybridshell Series",
    colors: [
      {
        color: "#202020",
        url: "hybridshell-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "hybridshell-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#FFBFBF",
        url: "hybridshell-series-airpods-pro-case-pink",
      },
    ],
    selectedColor: "#FFBFBF",
    images: [
      hybridshellAirPodsProPink1,
      hybridshellAirPodsProPink2,
      hybridshellAirPodsProPink3,
      hybridshellAirPodsProPink4,
    ],
    description:
      "The HybridShell Series features the tough protection benefits of a polycarbonate hard shell and a proprietary dust resistant nano coating with a soft liquid silicone core. The two-piece design utilizes Restickable Secure Adhesive (RSA) in the lid to provide an ultra secure lid while the body is precisely engineered for a snug fit. \n\n The Stealth Button on the rear of the HybridShell Series case allows for intuitive access to the setup button on the rear of the AirPods charging case. The HybridShell Series Keychain case offers a convenient way to clip and carry your AirPods on the go.",
    note: "",
    banners: [hybridshellBannerPink1, hybridshellBannerPink2, hybridshellBannerPink3],
    posters: [hybridshellPosterPink1, hybridshellPosterPink2, hybridshellPosterPink3],
    relatedProducts: [
      "hybridshell-series-airpods-pro-case-black",
      "hybridshell-series-airpods-pro-case-cobalt-blue",
      "hybridshell-series-airpods-pro-case-keychain-cobalt-blue",
    ],
    bottomBanner: { img: bottomBannerCobaltBlue, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
      next: {
        img: nextCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
    },
  },
];

export default hybridshell;

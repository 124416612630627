// Baby blue
import elevateProBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Baby Blue/pro-elevate-bbl-1.png";
import elevateProBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Baby Blue/pro-elevate-bbl-2.png";
import elevateProBannerBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Baby Blue/pro-elevate-bbl-banner-1.jpg";
import elevateProBannerBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Baby Blue/pro-elevate-bbl-banner-2.jpg";
import elevateProBannerBabyBlue3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Baby Blue/pro-elevate-bbl-banner-3.jpg";

// Black
import elevateProBlack1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Black/pro-elevate-blk-1.png";
import elevateProBlack2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Black/pro-elevate-blk-2.png";
import elevateProBannerBlack1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Black/pro-elevate-blk-banner-1.jpg";
import elevateProBannerBlack2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Black/pro-elevate-blk-banner-2.jpg";
import elevateProBannerBlack3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Black/pro-elevate-blk-banner-3.jpg";

// Cobalt Blue
import elevateProCobalBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Cobalt Blue/pro-elevate-cbb-1.png";
import elevateProCobalBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Cobalt Blue/pro-elevate-cbb-2.png";
import elevateProBannerCobalBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Cobalt Blue/pro-elevate-cbb-banner-1.jpg";
import elevateProBannerCobalBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Cobalt Blue/pro-elevate-cbb-banner-2.jpg";
import elevateProBannerCobalBlue3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Cobalt Blue/pro-elevate-cbb-banner-3.jpg";

// Diamobd Blue
import elevateProDiamondBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Diamond Blue/pro-elevate-dmb-1.png";
import elevateProDiamondBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Diamond Blue/pro-elevate-dmb-2.png";
import elevateProBannerDiamondBlue1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Diamond Blue/pro-elevate-dmb-banner-1.jpg";
import elevateProBannerDiamondBlue2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Diamond Blue/pro-elevate-dmb-banner-2.jpg";
import elevateProBannerDiamondBlue3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Diamond Blue/pro-elevate-dmb-banner-3.jpg";

// Grey
import elevateProGrey1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Grey/pro-elevate-gry-1.png";
import elevateProGrey2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Grey/pro-elevate-gry-2.png";
import elevateProBannerGrey1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Grey/pro-elevate-gry-banner-1.jpg";
import elevateProBannerGrey2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Grey/pro-elevate-gry-banner-2.jpg";
import elevateProBannerGrey3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Grey/pro-elevate-gry-banner-3.jpg";

// Red
import elevateProRed1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Red/pro-elevate-red-1.png";
import elevateProRed2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Red/pro-elevate-red-2.png";
import elevateProBannerRed1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Red/pro-elevate-red-banner-1.jpg";
import elevateProBannerRed2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Red/pro-elevate-red-banner-2.jpg";
import elevateProBannerRed3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Red/pro-elevate-red-banner-3.jpg";

// Lavender
import elevateProLavender1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Lavender/pro-elevate-lav-1.png";
import elevateProLavender2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Lavender/pro-elevate-lav-2.png";
import elevateProBannerLavender1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Lavender/pro-elevate-lav-banner-1.jpg";
import elevateProBannerLavender2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Lavender/pro-elevate-lav-banner-2.jpg";
import elevateProBannerLavender3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Lavender/pro-elevate-lav-banner-3.jpg";

// Pink
import elevateProPink1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Blush Pink/pro-elevate-bpn-1.png";
import elevateProPink2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Blush Pink/pro-elevate-bpn-2.png";
import elevateProBannerPink1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Blush Pink/pro-elevate-bpn-banner-1.jpg";
import elevateProBannerPink2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Blush Pink/pro-elevate-bpn-banner-2.jpg";
import elevateProBannerPink3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Blush Pink/pro-elevate-bpn-banner-3.jpg";

// White
import elevateProWhite1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/White/pro-elevate-wht-1.png";
import elevateProWhite2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/White/pro-elevate-wht-2.png";
import elevateProBannerWhite1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/White/pro-elevate-wht-banner-1.jpg";
import elevateProBannerWhite2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/White/pro-elevate-wht-banner-2.jpg";
import elevateProBannerWhite3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/White/pro-elevate-wht-banner-3.jpg";

// Yellow
import elevateProYellow1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Pastel Yellow/pro-elevate-pyl-1.png";
import elevateProYellow2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Pastel Yellow/pro-elevate-pyl-2.png";
import elevateProBannerYellow1 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Pastel Yellow/pro-elevate-pyl-banner-1.jpg";
import elevateProBannerYellow2 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Pastel Yellow/pro-elevate-pyl-banner-2.jpg";
import elevateProBannerYellow3 from "../../assets/img/products/products/Single Product/ELEVATE PRO/Pastel Yellow/pro-elevate-pyl-banner-3.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - Artisan Pro - Red.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-elevateswarovski.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-airdockz.jpg";

const elevatePro = [
  // Baby blue
  {
    id: "elevate-series-airpods-pro-case-baby-blue",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#A2C3E9",
    images: [elevateProBabyBlue1, elevateProBabyBlue2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-baby-blue",
      },
    },
    banners: [elevateProBannerBabyBlue1, elevateProBannerBabyBlue2, elevateProBannerBabyBlue3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-black",
      "elevate-series-airpods-pro-case-cobalt-blue",
      "elevate-series-airpods-pro-case-diamond-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Black
  {
    id: "elevate-series-airpods-pro-case-black",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#202020",
    images: [elevateProBlack1, elevateProBlack2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-black",
      },
    },
    banners: [elevateProBannerBlack1, elevateProBannerBlack2, elevateProBannerBlack3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-baby-blue",
      "elevate-series-airpods-pro-case-cobalt-blue",
      "elevate-series-airpods-pro-case-diamond-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Cobalt Blue
  {
    id: "elevate-series-airpods-pro-case-cobalt-blue",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [elevateProCobalBlue1, elevateProCobalBlue2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-cobalt-blue",
      },
    },
    banners: [elevateProBannerCobalBlue1, elevateProBannerCobalBlue2, elevateProBannerCobalBlue3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-black",
      "elevate-series-airpods-pro-case-diamond-blue",
      "elevate-series-airpods-pro-case-grey",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Diamond Blue
  {
    id: "elevate-series-airpods-pro-case-diamond-blue",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#6CE8D8",
    images: [elevateProDiamondBlue1, elevateProDiamondBlue2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
    },
    banners: [elevateProBannerDiamondBlue1, elevateProBannerDiamondBlue2, elevateProBannerDiamondBlue3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-cobalt-blue",
      "elevate-series-airpods-pro-case-grey",
      "elevate-series-airpods-pro-case-red",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Grey
  {
    id: "elevate-series-airpods-pro-case-grey",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#B1BDC2",
    images: [elevateProGrey1, elevateProGrey2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-grey",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-grey",
      },
    },
    banners: [elevateProBannerGrey1, elevateProBannerGrey2, elevateProBannerGrey3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-diamond-blue",
      "elevate-series-airpods-pro-case-red",
      "elevate-series-airpods-pro-case-lavender",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Red
  {
    id: "elevate-series-airpods-pro-case-red",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#E14242",
    images: [elevateProRed1, elevateProRed2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-red",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-red",
      },
    },
    banners: [elevateProBannerRed1, elevateProBannerRed2, elevateProBannerRed3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-grey",
      "elevate-series-airpods-pro-case-lavender",
      "elevate-series-airpods-pro-case-pink",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Lavender
  {
    id: "elevate-series-airpods-pro-case-lavender",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#BB9EFF",
    images: [elevateProLavender1, elevateProLavender2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-lavender",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-lavender",
      },
    },
    banners: [elevateProBannerLavender1, elevateProBannerLavender2, elevateProBannerLavender3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-red",
      "elevate-series-airpods-pro-case-pink",
      "elevate-series-airpods-pro-case-white",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Pink
  {
    id: "elevate-series-airpods-pro-case-pink",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#FFB5CA",
    images: [elevateProPink1, elevateProPink2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-pink",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-pink",
      },
    },
    banners: [elevateProBannerPink1, elevateProBannerPink2, elevateProBannerPink3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-lavender",
      "elevate-series-airpods-pro-case-white",
      "elevate-series-airpods-pro-case-yellow",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // White
  {
    id: "elevate-series-airpods-pro-case-white",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [elevateProWhite1, elevateProWhite2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails.  \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-white",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-white",
      },
    },
    banners: [elevateProBannerWhite1, elevateProBannerWhite2, elevateProBannerWhite3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-pink",
      "elevate-series-airpods-pro-case-yellow",
      "elevate-series-airpods-pro-case-baby-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },

  // Yellow
  {
    id: "elevate-series-airpods-pro-case-yellow",
    name: "Elevate Series \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-airpods-pro-case-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-airpods-pro-case-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-airpods-pro-case-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-airpods-pro-case-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-airpods-pro-case-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-airpods-pro-case-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-airpods-pro-case-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-airpods-pro-case-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-airpods-pro-case-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-airpods-pro-case-yellow",
      },
    ],
    selectedColor: "#E9D5A7",
    images: [elevateProYellow1, elevateProYellow2],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea.\n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-yellow",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-yellow",
      },
    },
    banners: [elevateProBannerYellow1, elevateProBannerYellow2, elevateProBannerYellow3],
    posters: [],
    relatedProducts: [
      "elevate-series-airpods-pro-case-white",
      "elevate-series-airpods-pro-case-baby-blue",
      "elevate-series-airpods-pro-case-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
    },
  },
];

export default elevatePro;

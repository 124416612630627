/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./Home.module.scss";
import Slider from "react-slick";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import ButtonBorder from "../../components/ButtonBorder";

import earbuddyz from "../../assets/img/home/slider1/home-slider-1-comp.png";
import caseBlack from "../../assets/img/home/slider1/home-slider-2-comp.png";
import case2 from "../../assets/img/home/slider1/home-slider-3-comp.png";
import kit from "../../assets/img/home/slider1/home-slider-4-comp.png";

import topBanner from "../../assets/img/home/banners/top-banner.jpg";
import FavProduct from "../../components/FavProduct";

import bannerSwarovski from "../../assets/img/home/banners/home-swarovski-banner-comp.jpg";
import SocialItem from "../../components/SocialItem";

import artisanProd from "../../assets/img/home/products/products-artisanpro-comp.png";
import earbuddyzProd from "../../assets/img/home/products/products-earbuddyz2-black-comp.png";
import airLockzProd from "../../assets/img/home/products/products-airlockz-cblue-comp.png";
import airCareProd from "../../assets/img/home/products/products-aircare-comp.png";
import elevatexProd from "../../assets/img/home/products/products-elevatexswarovski-comp.png";

import bannerArtisan from "../../assets/img/home/banners/home-aritsan-banner-comp.jpg";
import GeneralStore from "../../stores/GeneralStore";
import { useHistory } from "react-router";

const Home = () => {
  const history = useHistory();
  const generalStore = useContext(GeneralStore);
  const [showWhite, setShowWhite] = useState(false);
  const sliderRef = useRef();
  const sliderProductRef = useRef();

  const settingsMain = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const settingsProducts = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    generalStore.setShowWhiteNavbar(false);
  }, []);

  return (
    <>
      <section className={styles.mainSlider}>
        <Slider
          {...settingsMain}
          className={showWhite && "white-dots"}
          ref={sliderRef}
          afterChange={(index) => {
            if (index === 1) {
              generalStore.setShowWhiteNavbar(true);
              setShowWhite(true);
            } else {
              generalStore.setShowWhiteNavbar(false);
              setShowWhite(false);
            }
          }}
        >
          <div>
            <div className={styles.sliderDiv}>
              <div className={styles.leftDiv}>
                <div>
                  <Icon
                    className="mb-4 cursor-pointer"
                    icon="arrow"
                    arrowLeft
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  />
                  <p className={styles.normal}>AirPods Pro</p>
                  <p className={styles.big}>
                    Earbuddyz for <br /> AirPods Pro
                  </p>
                </div>
              </div>

              <div className={styles.centertDiv}>
                <p className={styles.normal}>AirPods Pro</p>
                <p className={styles.big}>Earbuddyz for AirPods Pro</p>

                <img src={earbuddyz} alt="Earbuddyz for AirPods Pro" />

                <p className={styles.normal}>The Ultimate Fit for AirPods Pro</p>
              </div>

              <div className={styles.rightDiv}>
                <div>
                  <Icon
                    className="mb-5 cursor-pointer"
                    icon="arrow"
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  />
                  <p className={styles.normal}>
                    The Ultimate Fit <br /> for AirPods Pro
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(styles.sliderDiv, styles.div2)}>
              <div className={styles.leftDiv}>
                <div>
                  <Icon
                    className="mb-4 cursor-pointer"
                    icon="arrow"
                    arrowLeft
                    showWhite
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  />
                  <p className={styles.normal}>AirPods Pro</p>
                  <p className={styles.big}>
                    Artisan Series <br /> Leather Case
                  </p>
                </div>
              </div>
              <div className={styles.centertDiv}>
                <p className={styles.normal}>AirPods Pro</p>
                <p className={styles.big}>Artisan Series Leather Case</p>

                <img src={caseBlack} alt="Artisan Series Leather Case" />

                <p className={styles.normal}>Made From Handcrafted Genuine Italian Leather</p>
              </div>
              <div className={styles.rightDiv}>
                <div>
                  <Icon
                    className="mb-5 cursor-pointer"
                    icon="arrow"
                    showWhite
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  />
                  <p className={styles.normal}>
                    Made From Handcrafted <br /> Genuine Italian Leather
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(styles.sliderDiv, styles.div3)}>
              <div className={styles.leftDiv}>
                <div>
                  <Icon
                    className="mb-4 cursor-pointer"
                    icon="arrow"
                    arrowLeft
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  />
                  <p className={styles.normal}>AirPods</p>
                  <p className={styles.big}>
                    Elevate x Swarovski <br /> Case for AirPods
                  </p>
                </div>
              </div>
              <div className={styles.centertDiv}>
                <p className={styles.normal}>AirPods</p>
                <p className={styles.big}>Elevate x Swarovski Case for AirPods</p>

                <img src={case2} alt="Artisan Series Leather Case" />

                <p className={styles.normal}>
                  Genuine Brilliant <br /> Swarovski Crystals
                </p>
              </div>
              <div className={styles.rightDiv}>
                <div>
                  <Icon
                    className="mb-5 cursor-pointer"
                    icon="arrow"
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  />
                  <p className={styles.normal}>
                    Genuine Brilliant <br /> Swarovski Crystals
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={cx(styles.sliderDiv, styles.div4)}>
              <div className={styles.leftDiv}>
                <div>
                  <Icon
                    className="mb-4 cursor-pointer"
                    icon="arrow"
                    arrowLeft
                    onClick={() => {
                      sliderRef.current.slickPrev();
                    }}
                  />
                  <p className={styles.normal}>For AirPods & AirPods Pro</p>
                  <p className={styles.big}>
                    AirCare Cleaning <br /> Kit for AirPods
                  </p>
                </div>
              </div>
              <div className={styles.centertDiv}>
                <p className={styles.normal}>For AirPods & AirPods Pro</p>
                <p className={styles.big}>AirCare Cleaning Kit for AirPods</p>

                <img src={kit} alt="AirCare Cleaning Kit for AirPods" />

                <p className={styles.normal}>Safely Cleans your AirPods & Maximizes Sound Performance</p>
              </div>
              <div className={styles.rightDiv}>
                <div>
                  <Icon
                    className="mb-5 cursor-pointer"
                    icon="arrow"
                    onClick={() => {
                      sliderRef.current.slickNext();
                    }}
                  />
                  <p className={styles.normal}>
                    Safely Cleans your AirPods & <br /> Maximizes Sound Performance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>

        <div className={cx(styles.socialDiv, showWhite && styles.white)}>
          <Icon
            className="cursor-pointer mr-4"
            icon="facebook"
            showWhite={showWhite}
            onClick={() => {
              window.open("https://www.facebook.com/KeyBudzOfficial", "_blank");
            }}
          />

          <Icon
            className="cursor-pointer mr-4"
            icon="instagram"
            showWhite={showWhite}
            onClick={() => {
              window.open("https://www.instagram.com/keybudz/", "_blank");
            }}
          />

          <Icon
            className="cursor-pointer"
            icon="twitter"
            showWhite={showWhite}
            onClick={() => {
              window.open("https://twitter.com/Keybudz", "_blank");
            }}
          />
        </div>

        <Button
          className={cx(styles.button, showWhite && styles.white)}
          text="Explore now"
          icon="arrow"
          showWhite={showWhite}
          onClick={() => {
            history.push("/products");
          }}
        />

        <ButtonBorder
          className={cx(styles.buttonMobile, showWhite && styles.white)}
          text="Explore now"
          icon="arrow"
          showWhite={showWhite}
          colorWhite={showWhite}
          onClick={() => {
            history.push("/products");
          }}
        />
      </section>

      <section className={styles.topBannerSection}>
        <img className={styles.bannerImg} src={topBanner} alt="banner" />
      </section>

      <section className={styles.favoritesSection}>
        <h1 className="px-3 section-title">Discover Our Favorites</h1>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <FavProduct type="artisan" />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <FavProduct type="earbuddyz" />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <FavProduct type="airtags" />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <FavProduct type="airstaps" />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.bannerSection}>
        <img className={styles.bannerImg} src={bannerSwarovski} alt="banner swarovski" />
        <ButtonBorder
          className={styles.button}
          text="Explore Now"
          icon="arrow"
          onClick={() => {
            history.push("products/elevate-swarovski-for-airpods-pro");
          }}
        />
      </section>

      <section className={styles.followSection}>
        <div className="container-fluid">
          <p className="section-subtitle text-center mb-3">@keybudz</p>
          <h1 className="section-title text-center mb-6">Follow Us</h1>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 p-0">
              <SocialItem type="insta1" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <SocialItem type="insta2" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <SocialItem type="insta3" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <SocialItem type="insta4" />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.productsSection}>
        <h1 className="section-title text-center mb-5">Products</h1>

        <div className={styles.sliderWrapper}>
          <Icon
            className={styles.arrowLeft}
            icon="arrow"
            onClick={() => {
              sliderProductRef.current.slickPrev();
            }}
          />

          <Icon
            className={styles.arrowRight}
            icon="arrow"
            onClick={() => {
              sliderProductRef.current.slickNext();
            }}
          />

          <Slider className="products-slider" {...settingsProducts} ref={sliderProductRef}>
            <div className={styles.sliderDiv}>
              <div className={styles.centerDiv}>
                <h5 className={styles.title}>
                  Artisan Series <br /> AirPods Pro Case
                </h5>

                <div className={styles.imgDiv}>
                  <img src={artisanProd} alt="Artisan Series AirPods Pro Case" />

                  <ButtonBorder
                    className={styles.button}
                    text="Explore Now"
                    icon="arrow"
                    onClick={() => {
                      history.push("products/artisan-series-airpods-pro-case-brown");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.sliderDiv}>
              <div className={styles.centerDiv}>
                <h5 className={styles.title}>
                  EarBuddyz 2.0 <br /> AirPods Hooks
                </h5>

                <div className={styles.imgDiv}>
                  <img src={earbuddyzProd} alt="EarBuddyz 2.0 AirPods Hooks" />

                  <ButtonBorder
                    className={styles.button}
                    text="Explore Now"
                    icon="arrow"
                    onClick={() => {
                      history.push("products/earbuddyz-for-airpods-gen-1-2-black");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.sliderDiv}>
              <div className={styles.centerDiv}>
                <h5 className={styles.title}>
                  AirLockz <br /> AirPods Dock
                </h5>

                <div className={styles.imgDiv}>
                  <img src={airLockzProd} alt="AirLockz AirPods Dock" />

                  <ButtonBorder
                    className={styles.button}
                    text="Explore Now"
                    icon="arrow"
                    onClick={() => {
                      history.push("products/airlockz-watch-dock-for-airpods-black");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.sliderDiv}>
              <div className={styles.centerDiv}>
                <h5 className={styles.title}>
                  AirCare <br /> AirPods Cleaning Kit
                </h5>

                <div className={styles.imgDiv}>
                  <img src={airCareProd} alt="AirCare AirPods Cleaning Kit" />

                  <ButtonBorder
                    className={styles.button}
                    text="Explore Now"
                    icon="arrow"
                    onClick={() => {
                      history.push("products/artisan-airpods-cleaning-kit");
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.sliderDiv}>
              <div className={styles.centerDiv}>
                <h5 className={styles.title}>
                  Elevate x swarovski <br /> for AirPods Gen 1/2
                </h5>

                <div className={styles.imgDiv}>
                  <img src={elevatexProd} alt="Elevate x swarovski for AirPods Gen 1/2" />

                  <ButtonBorder
                    className={styles.button}
                    text="Explore Now"
                    icon="arrow"
                    onClick={() => {
                      history.push("products/elevate-swarovski-for-airpods-pro");
                    }}
                  />
                </div>
              </div>
            </div>
          </Slider>

          <ButtonBorder
            className={styles.button}
            text="View All Products"
            showBorder
            onClick={() => {
              history.push("products");
            }}
          />
        </div>
      </section>

      <section className={styles.bannerSection2}>
        <img className={styles.bannerImg} src={bannerArtisan} alt="banner artisan" />

        <ButtonBorder
          className={styles.button}
          text="Explore Now"
          showBorder
          icon="arrow"
          onClick={() => {
            history.push("products/artisan-series-airpods-pro-case-brown");
          }}
        />
      </section>
    </>
  );
};

export default Home;

import earbuddyzUltraProImg1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-1.png";
import earbuddyzUltraProImg2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-2.png";
import earbuddyzUltraProImg3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-3.png";
import earbuddyzUltraProImg4 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-4.png";
import earbuddyzUltraProBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-banner-1.jpg";
import earbuddyzUltraProBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-banner-2.jpg";
import earbuddyzUltraProBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra Pro/eb-ultra-pro-banner-3.jpg";

import BottomBanner from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-earbuddyzultra.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-elevate.jpg";

const earbuddyzUltraPro = [
  {
    id: "earbuddyz-ultra-for-airpods-pro",
    name: "EarBuddyz ULTRA \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Hooks",
    series: "EarBuddyz Ultra Series",
    colors: [],
    selectedColor: "",
    images: [earbuddyzUltraProImg1, earbuddyzUltraProImg2, earbuddyzUltraProImg3, earbuddyzUltraProImg4],
    description:
      "EarBuddyz Ultra take the original EarBuddyz one step further by combining the comfort and security of our original hooks with improved noise isolation and overall sound performance. A spherical bud was designed to create a better seal to help keep unwanted noise out and the stuff that matters in. \n\n EarBuddyz Ultra are engineered from a premium dual durometer silicone which allows for maximum comfort without compromising a secure fit.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
    },
    banners: [earbuddyzUltraProBanner1, earbuddyzUltraProBanner2, earbuddyzUltraProBanner3],
    posters: [],
    insideImages: null,
    relatedProducts: [
      "earbuddyz-ultra-for-airpods-gen-1-2-white",
      "earbuddyz-ultra-for-airpods-gen-1-2-black",
      "earbuddyz-for-airpods-pro-white",
    ],
    bottomBanner: { img: BottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-series-for-airpods-gen-1-2-lavender",
      },
    },
  },
];

export default earbuddyzUltraPro;

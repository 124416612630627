// Black
import artisanProBlack1 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-1.png";
import artisanProBlack2 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-2.png";
import artisanProBlack3 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-3.png";
import artisanProBlack4 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-4.png";
import artisanProBannerBlack1 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-banner-1.jpg";
import artisanProBannerBlack2 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-banner-2.jpg";
import artisanProBannerBlack3 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-banner-3.jpg";
import artisanProPosterBlack1 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-poster-1.jpg";
import artisanProPosterBlack2 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-poster-2.jpg";
import artisanProPosterBlack3 from "../../assets/img/products/products/Single Product/Artisan Pro/Black/art-pro-blk-poster-3.jpg";

import bottomBannerBlack from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-artisan.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-earbuddyz.jpg";

// Brown
import artisanProBrown1 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-1.png";
import artisanProBrown2 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-2.png";
import artisanProBrown3 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-3.png";
import artisanProBrown4 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-4.png";
import artisanProBannerBrown1 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-banner-1.jpg";
import artisanProBannerBrown2 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-banner-2.jpg";
import artisanProBannerBrown3 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-banner-3.jpg";
import artisanProPosterBrown1 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-poster-1.jpg";
import artisanProPosterBrown2 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-poster-2.jpg";
import artisanProPosterBrown3 from "../../assets/img/products/products/Single Product/Artisan Pro/Brown/art-pro-brw-poster-3.jpg";

// Red
import artisanProRed1 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-1.png";
import artisanProRed2 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-2.png";
import artisanProRed3 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-3.png";
import artisanProRed4 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-4.png";
import artisanProBannerRed1 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-banner-1.jpg";
import artisanProBannerRed2 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-banner-2.jpg";
import artisanProBannerRed3 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-banner-3.jpg";
import artisanProPosterRed1 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-poster-1.jpg";
import artisanProPosterRed2 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-poster-2.jpg";
import artisanProPosterRed3 from "../../assets/img/products/products/Single Product/Artisan Pro/Red/art-pro-red-poster-3.jpg";

// Grey
import artisanProGrey1 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-1.png";
import artisanProGrey2 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-2.png";
import artisanProGrey3 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-3.png";
import artisanProGrey4 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-4.png";
import artisanProBannerGrey1 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-banner-1.jpg";
import artisanProBannerGrey2 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-banner-2.jpg";
import artisanProBannerGrey3 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-banner-3.jpg";
import artisanProPosterGrey1 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-poster-1.jpg";
import artisanProPosterGrey2 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-poster-2.jpg";
import artisanProPosterGrey3 from "../../assets/img/products/products/Single Product/Artisan Pro/Slate/art-pro-slt-poster-3.jpg";

const artisanPro = [
  {
    id: "artisan-series-airpods-pro-case-black",
    name: "Artisan Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-pro-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-pro-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-pro-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-pro-case-grey",
      },
    ],
    selectedColor: "#202020",
    images: [artisanProBlack1, artisanProBlack2, artisanProBlack3, artisanProBlack4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell.\n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-black",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-black",
      },
    },
    banners: [artisanProBannerBlack1, artisanProBannerBlack2, artisanProBannerBlack3],
    posters: [artisanProPosterBlack1, artisanProPosterBlack2, artisanProPosterBlack3],
    relatedProducts: [
      "artisan-series-airpods-pro-case-brown",
      "artisan-series-airpods-pro-case-red",
      "artisan-series-airpods-pro-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
    },
  },

  {
    id: "artisan-series-airpods-pro-case-brown",
    name: "Artisan Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-pro-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-pro-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-pro-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-pro-case-grey",
      },
    ],
    selectedColor: "#673E20",
    images: [artisanProBrown1, artisanProBrown2, artisanProBrown3, artisanProBrown4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell.\n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-brown",
      },
    },
    banners: [artisanProBannerBrown1, artisanProBannerBrown2, artisanProBannerBrown3],
    posters: [artisanProPosterBrown1, artisanProPosterBrown2, artisanProPosterBrown3],
    relatedProducts: [
      "artisan-series-airpods-pro-case-black",
      "artisan-series-airpods-pro-case-red",
      "artisan-series-airpods-pro-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
    },
  },

  {
    id: "artisan-series-airpods-pro-case-red",
    name: "Artisan Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-pro-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-pro-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-pro-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-pro-case-grey",
      },
    ],
    selectedColor: "#E14242",
    images: [artisanProRed1, artisanProRed2, artisanProRed3, artisanProRed4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell.\n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-red",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-red",
      },
    },
    banners: [artisanProBannerRed1, artisanProBannerRed2, artisanProBannerRed3],
    posters: [artisanProPosterRed1, artisanProPosterRed2, artisanProPosterRed3],
    relatedProducts: [
      "artisan-series-airpods-pro-case-black",
      "artisan-series-airpods-pro-case-brown",
      "artisan-series-airpods-pro-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
    },
  },

  {
    id: "artisan-series-airpods-pro-case-grey",
    name: "Artisan Series \n AirPods Pro Case",
    device: "AirPods Pro",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-pro-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-pro-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-pro-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-pro-case-grey",
      },
    ],
    selectedColor: "#B1BDC2",
    images: [artisanProGrey1, artisanProGrey2, artisanProGrey3, artisanProGrey4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell.\n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-grey",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
    banners: [artisanProBannerGrey1, artisanProBannerGrey2, artisanProBannerGrey3],
    posters: [artisanProPosterGrey1, artisanProPosterGrey2, artisanProPosterGrey3],
    relatedProducts: [
      "artisan-series-airpods-pro-case-black",
      "artisan-series-airpods-pro-case-brown",
      "artisan-series-airpods-pro-case-red",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
    },
  },
];

export default artisanPro;

// Black
import artisan12Black1 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-1.png";
import artisan12Black2 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-2.png";
import artisan12Black3 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-3.png";
import artisan12Black4 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-4.png";
import artisan12BannerBlack1 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-banner-1.jpg";
import artisan12BannerBlack2 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-banner-2.jpg";
import artisan12BannerBlack3 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-banner-3.jpg";
import artisan12PosterBlack1 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-poster-1.jpg";
import artisan12PosterBlack2 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-poster-2.jpg";
import artisan12PosterBlack3 from "../../assets/img/products/products/Single Product/Artisan/Black/art-blk-poster-3.jpg";

import bottomBannerBlack from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-hybridshellkeychain.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-artisanpro.jpg";

// Brown
import artisan12Brown1 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-1.png";
import artisan12Brown2 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-2.png";
import artisan12Brown3 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-3.png";
import artisan12Brown4 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-4.png";
import artisan12BannerBrown1 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-banner-1.jpg";
import artisan12BannerBrown2 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-banner-2.jpg";
import artisan12BannerBrown3 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-banner-3.jpg";
import artisan12PosterBrown1 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-poster-1.jpg";
import artisan12PosterBrown2 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-poster-2.jpg";
import artisan12PosterBrown3 from "../../assets/img/products/products/Single Product/Artisan/Brown/art-brw-poster-3.jpg";

// Red
import artisan12Red1 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-1.png";
import artisan12Red2 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-2.png";
import artisan12Red3 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-3.png";
import artisan12Red4 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-4.png";
import artisan12BannerRed1 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-banner-1.jpg";
import artisan12BannerRed2 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-banner-2.jpg";
import artisan12BannerRed3 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-banner-3.jpg";
import artisan12PosterRed1 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-poster-1.jpg";
import artisan12PosterRed2 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-poster-2.jpg";
import artisan12PosterRed3 from "../../assets/img/products/products/Single Product/Artisan/Red/art-red-poster-3.jpg";

// Grey
import artisan12Grey1 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-1.png";
import artisan12Grey2 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-2.png";
import artisan12Grey3 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-3.png";
import artisan12Grey4 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-4.png";
import artisan12BannerGrey1 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-banner-1.jpg";
import artisan12BannerGrey2 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-banner-2.jpg";
import artisan12BannerGrey3 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-banner-3.jpg";
import artisan12PosterGrey1 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-poster-1.jpg";
import artisan12PosterGrey2 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-poster-2.jpg";
import artisan12PosterGrey3 from "../../assets/img/products/products/Single Product/Artisan/Slate/art-slt-poster-3.jpg";

const artisan12 = [
  {
    id: "artisan-series-airpods-1-2-case-black",
    name: "Artisan Series \n AirPods 1/2 Case",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-1-2-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-1-2-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-1-2-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-1-2-case-grey",
      },
    ],
    selectedColor: "#202020",
    images: [artisan12Black1, artisan12Black2, artisan12Black3, artisan12Black4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell. \n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-black",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-black",
      },
    },
    banners: [artisan12BannerBlack1, artisan12BannerBlack2, artisan12BannerBlack3],
    posters: [artisan12PosterBlack1, artisan12PosterBlack2, artisan12PosterBlack3],
    relatedProducts: [
      "artisan-series-airpods-1-2-case-brown",
      "artisan-series-airpods-1-2-case-red",
      "artisan-series-airpods-1-2-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
  },

  {
    id: "artisan-series-airpods-1-2-case-brown",
    name: "Artisan Series \n AirPods 1/2 Case",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-1-2-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-1-2-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-1-2-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-1-2-case-grey",
      },
    ],
    selectedColor: "#673E20",
    images: [artisan12Brown1, artisan12Brown2, artisan12Brown3, artisan12Brown4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell. \n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-brown",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-brown",
      },
    },
    banners: [artisan12BannerBrown1, artisan12BannerBrown2, artisan12BannerBrown3],
    posters: [artisan12PosterBrown1, artisan12PosterBrown2, artisan12PosterBrown3],
    relatedProducts: [
      "artisan-series-airpods-1-2-case-black",
      "artisan-series-airpods-1-2-case-red",
      "artisan-series-airpods-1-2-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
  },

  {
    id: "artisan-series-airpods-1-2-case-red",
    name: "Artisan Series \n AirPods 1/2 Case",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-1-2-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-1-2-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-1-2-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-1-2-case-grey",
      },
    ],
    selectedColor: "#E14242",
    images: [artisan12Red1, artisan12Red2, artisan12Red3, artisan12Red4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell. \n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-red",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-red",
      },
    },
    banners: [artisan12BannerRed1, artisan12BannerRed2, artisan12BannerRed3],
    posters: [artisan12PosterRed1, artisan12PosterRed2, artisan12PosterRed3],
    relatedProducts: [
      "artisan-series-airpods-1-2-case-black",
      "artisan-series-airpods-1-2-case-brown",
      "artisan-series-airpods-1-2-case-grey",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
  },

  {
    id: "artisan-series-airpods-1-2-case-grey",
    name: "Artisan Series \n AirPods 1/2 Case",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Artisan Leather Series",
    colors: [
      {
        color: "#202020",
        url: "artisan-series-airpods-1-2-case-black",
      },
      {
        color: "#673E20",
        url: "artisan-series-airpods-1-2-case-brown",
      },
      {
        color: "#E14242",
        url: "artisan-series-airpods-1-2-case-red",
      },
      {
        color: "#B1BDC2",
        url: "artisan-series-airpods-1-2-case-grey",
      },
    ],
    selectedColor: "#B1BDC2",
    images: [artisan12Grey1, artisan12Grey2, artisan12Grey3, artisan12Grey4],
    description:
      "The Artisan Series from KeyBudz is hand crafted by highly skilled artisans using top grain genuine leather selected exclusively to be used with our cases. Each case is constructed from a 4-piece shell. \n\n The shells are individually wrapped in a pebble grain finished leather and then heat treated, a process taking nearly half a day to complete RSA (Restickable Secure Adheisive) is preapplied to the lid of the case to ensure a long lasting secure fit. The Artisan Series combines an unparalleled level of craftmanship and premium tannage to create the world’s first fully wrapped top grain leather case for the AirPods Pro.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/artisan-series-airpods-1-2-case-grey",
      },
      pro: {
        img: null,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
    banners: [artisan12BannerGrey1, artisan12BannerGrey2, artisan12BannerGrey3],
    posters: [artisan12PosterGrey1, artisan12PosterGrey2, artisan12PosterGrey3],
    relatedProducts: [
      "artisan-series-airpods-1-2-case-black",
      "artisan-series-airpods-1-2-case-brown",
      "artisan-series-airpods-1-2-case-red",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/hybridshell-series-airpods-pro-case-keychain-pink",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
    },
  },
];

export default artisan12;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import GeneralStore from "../../stores/GeneralStore";
import styles from "./About.module.scss";
import eImg from "../../assets/img/about/e.png";
import about1Img from "../../assets/img/about/about-1-comp.jpg";
import about2Img from "../../assets/img/about/about-2-comp.png";
import about3Img from "../../assets/img/about/about-3-comp.png";
import gallery1Img from "../../assets/img/about/about-gallery-1-comp.jpg";
import gallery2Img from "../../assets/img/about/about-gallery-2-comp.png";
import gallery3Img from "../../assets/img/about/about-gallery-3-comp.png";
import gallery4Img from "../../assets/img/about/about-gallery-4-comp.png";
import buddyUpBanner from "../../assets/img/about/banner-buddyup-comp.png";
import ButtonBorder from "../../components/ButtonBorder";
import { useHistory } from "react-router";

const About = () => {
  const history = useHistory();
  const generalStore = useContext(GeneralStore);

  useEffect(() => {
    generalStore.setShowWhiteNavbar(true);
  }, []);

  return (
    <>
      <section className={styles.topBanner}></section>

      <section className={styles.aboutSection}>
        <div className={styles.firstDiv}>
          <div className="container">
            <div className="row position-relative">
              <img className={styles.eBg} src={eImg} alt="e bg" />

              <div className="col-12">
                <p>KeyBudz. was founded in 2016 and built on a simple principle:</p>

                <h1 className="section-title">To improve the AirPods experience for people everywhere.</h1>
              </div>
            </div>
          </div>

          <img className={styles.about1Img} src={about1Img} alt="about comp 1" />
        </div>

        <div className={styles.grayDiv}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h1 className={styles.aboutSubTitle}>AirPods Redefined.</h1>

                <img className={styles.about2Img} src={about2Img} alt="about comp 1" />
              </div>

              <div className="col-12 col-lg-4">
                <p>
                  We are a team of designers and thinkers that employ a human-centric design approach to create a
                  product offering that complements the unique lifestyles of individuals.
                  <br />
                  <br />
                  Our dedication to thoughtful design and creative solutions inspire people to do more of what they love
                  with their AirPods.
                </p>

                <p>
                  We've all been there... the never-ever land of constantly pushing in, twisting and relentless
                  readjusting of our Apple AirPods & Earpods.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.thirdDiv}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-2">
                <p>
                  EarBuddyz AirPods ear hooks eliminate the fuss and hassle of constantly adjusting your Apple earphones
                  and provide an ultra secure listening experience.
                </p>

                <img className={styles.about3Img} src={about3Img} alt="about comp 1" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.gallerySection}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 p-0">
              <img src={gallery2Img} alt="img 2" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <img src={gallery3Img} alt="img 3" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <img src={gallery1Img} alt="img 1" />
            </div>

            <div className="col-12 col-md-6 col-lg-3 p-0">
              <img src={gallery4Img} alt="img 4" />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.buddyUpBanner}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-2">
              <p>
                Engineered from high performance elastomers, EarBuddyz AirPods Hooks comfortably grip your ear and
                provide additional support by conforming to the groove of your inner ear. Experience twice the comfort
                and secure fit compared to using Apple earphones alone.
              </p>
            </div>

            <div className="col-12">
              <div className={styles.bannerDiv}>
                <img src={buddyUpBanner} alt="img 2" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.bottomBanner}>
        <ButtonBorder
          className={styles.button}
          text="Explore Now"
          showBorder
          showWhite
          colorWhite
          icon="arrow"
          onClick={() => {
            history.push("products/elevate-series-for-airpods-gen-1-2-baby-blue");
          }}
        />
      </section>
    </>
  );
};

export default About;

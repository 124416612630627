import React from "react";
import cx from "classnames";
import styles from "./Footer.module.scss";
import logo from "../assets/img/footer_logo.png";
import { useHistory } from "react-router";

const Footer = () => {
  const history = useHistory();

  return (
    <footer className={styles.footer}>
      <div className="container-fluid">
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>

        <div className={cx(styles.row, "row")}>
          <div className="col-12 col-md-2">
            <h1
              className={cx(styles.footerTitle, "section-title text-white cursor-pointer")}
              onClick={() => {
                history.push("/about");
              }}
            >
              About Us
            </h1>
            <h1
              className={cx(styles.footerTitle, "section-title text-white cursor-pointer")}
              onClick={() => {
                history.push("/products");
              }}
            >
              Products
            </h1>
            <h1 className={cx(styles.footerTitle, "section-title text-white")}>Sale</h1>
            <h1 className={cx(styles.footerTitle, "section-title text-white")}>Support</h1>
            <h1 className={cx(styles.footerTitle, "section-title text-white")}>Contact</h1>
          </div>

          <div className="col-12 col-md-2 offset-md-4">
            <h5 className={styles.sectionSubtitle}>Get in touch</h5>

            <p>
              1516 E Tropicana Ave Suite 295-2
              <br />
              Las Vegas, NV 89119
              <br />
              <a href="mailto:support@keybudz.com" className={styles.mailto}>
                support@keybudz.com
              </a>
            </p>
          </div>

          <div className="col-12 col-md-2">
            <h5 className={styles.sectionSubtitle}>Shop</h5>

            <ul className={styles.footerLink}>
              <li
                onClick={() => {
                  history.push("/products/hybridshell-series-airpods-pro-case-cobalt-blue");
                }}
              >
                HybridShell Series
              </li>
              <li
                onClick={() => {
                  history.push("/products/hybridshell-series-airpods-pro-case-keychain-cobalt-blue");
                }}
              >
                HybridShell Series Keychain
              </li>
              <li
                onClick={() => {
                  history.push("/products/artisan-series-airpods-1-2-case-black");
                }}
              >
                Artisan Series AirPods
              </li>
              <li
                onClick={() => {
                  history.push("/products/artisan-series-airpods-pro-case-black");
                }}
              >
                Artisan Series AirPods Pro
              </li>
              <li
                onClick={() => {
                  history.push("/products/earbuddyz-for-airpods-gen-1-2-white");
                }}
              >
                EarBuddyz for AirPods
              </li>
              <li
                onClick={() => {
                  history.push("/products/earbuddyz-for-airpods-pro-white");
                }}
              >
                EarBuddyz for AirPods Pro
              </li>
              <li
                onClick={() => {
                  history.push("/products/earbuddyz-ultra-for-airpods-gen-1-2-white");
                }}
              >
                EarBuddyz ULTRA for AirPods
              </li>
              <li
                onClick={() => {
                  history.push("/products/earbuddyz-ultra-for-airpods-pro");
                }}
              >
                EarBuddyz ULTRA for AirPods Pro
              </li>
              <li
                onClick={() => {
                  history.push("/products/elevate-series-for-airpods-gen-1-2-baby-blue");
                }}
              >
                Elevate Series AirPods
              </li>
              <li
                onClick={() => {
                  history.push("/products/elevate-swarovski-for-airpods-pro");
                }}
              >
                Elevate x Swarovski
              </li>
              <li
                onClick={() => {
                  history.push("/products/elevate-series-airpods-pro-case-baby-blue");
                }}
              >
                Elevate Series AirPods Pro
              </li>
              <li
                onClick={() => {
                  history.push("/products/airdockz-dock-for-airpods-black");
                }}
              >
                AirDockz
              </li>
              <li
                onClick={() => {
                  history.push("/products/airlockz-watch-dock-for-airpods-black");
                }}
              >
                AirLockz
              </li>
              <li
                onClick={() => {
                  history.push("/products/airstrapz-for-airpods-black");
                }}
              >
                AirDockz
              </li>
              <li
                onClick={() => {
                  history.push("/products/artisan-airpods-cleaning-kit");
                }}
              >
                AirCare
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-2">
            <h5 className={styles.sectionSubtitle}>Services</h5>

            <ul>
              <li>Shipping</li>
              <li>Returns</li>
              <li>Terms & Conditions</li>
              <li>FAQs</li>
            </ul>
          </div>
        </div>

        <div className={styles.bottomSection}>
          <ul>
            <li>
              <a href="https://www.facebook.com/KeyBudzOfficial" target="_blank" rel="noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/keybudz/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Keybudz" target="_blank" rel="noreferrer">
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.amazon.com/sp?_encoding=UTF8&asin=&isAmazonFulfilled=1&isCBA=&marketplaceID=ATVPDKIKX0DER&orderID=&protocol=current&seller=A244VGG23RG9QS&sshmPath="
                target="_blank"
                rel="noreferrer"
              >
                Amazon
              </a>
            </li>
          </ul>

          <div className={styles.copyright}>Copyright © 2021 keybudz.com</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

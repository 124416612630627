import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./FavProduct.module.scss";
import artImg from "../assets/img/home/favorites/favorites-artisanpro-comp.png";
import securedockImg from "../assets/img/home/favorites/favorites-securedock.png";
import earbuddyzImg from "../assets/img/home/favorites/favorites-earbuddyzpro-comp.png";
import airstapsImg from "../assets/img/home/favorites/favorites-airstrapzwhite-comp.png";
import lavenderImg from "../assets/img/home/favorites/favorites-elevatelavender-comp.png";
import Button from "./Button";

const FavProduct = ({ type }) => {
  const history = useHistory();

  const map = {
    artisan: {
      img: artImg,
      title: "Artisan Series",
      text: "AirPods Pro Case",
      url: "/products/artisan-series-airpods-pro-case-brown",
    },
    airtags: {
      img: securedockImg,
      title: "Secure Dock",
      text: "Silicone Holder for AirTag",
      url: "",
    },
    earbuddyz: {
      img: earbuddyzImg,
      title: "Earbuddyz for AirPods Pro",
      text: "AirPods Performance Hooks",
      url: "/products/earbuddyz-for-airpods-pro-white",
    },
    airstaps: {
      img: airstapsImg,
      title: "AirStrapz",
      text: "AirPods and AirPods Pro Straps",
      url: "/products/airstrapz-for-airpods-white",
    },
    lavender: {
      img: lavenderImg,
      title: "Elevate Series",
      text: "Case for AirPods 1/2",
      url: "/products/elevate-series-for-airpods-gen-1-2-lavender",
    },
  };

  return (
    <div className={cx(styles.favDiv, type === "airtags" && styles.airtags)}>
      <div className={styles.imgDiv}>
        <img src={map[type].img} alt="favorite product" />
      </div>

      <div className={styles.infoDiv}>
        <h5>{map[type].title}</h5>
        <p>{map[type].text}</p>
      </div>

      {map[type].url ? (
        <Button
          className={styles.button}
          text="Explore now"
          icon="arrow"
          onClick={() => {
            history.push(map[type].url);
          }}
        />
      ) : (
        <p className={styles.soon}>Coming Soon</p>
      )}
    </div>
  );
};

FavProduct.propTypes = {
  type: PropTypes.string.isRequired,
};

export default FavProduct;

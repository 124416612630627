// Black
import earbuddyzUltra12WiteImg1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-1.png";
import earbuddyzUltra12WiteImg2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-2.png";
import earbuddyzUltra12WiteImg3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-3.png";
import earbuddyzUltra12WiteImg4 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-4.png";
import earbuddyzUltra12WiteBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-banner-1.jpg";
import earbuddyzUltra12WiteBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-banner-2.jpg";
import earbuddyzUltra12WiteBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-banner-3.jpg";
import earbuddyzUltra12WitePoster1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-poster-1.jpg";
import earbuddyzUltra12WitePoster2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-poster-2.jpg";
import earbuddyzUltra12WitePoster3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/White/eb-ultra-wht-poster-3.jpg";

// White
import earbuddyzUltra12BlackImg1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-1.png";
import earbuddyzUltra12BlackImg2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-2.png";
import earbuddyzUltra12BlackImg3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-3.png";
import earbuddyzUltra12BlackImg4 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-4.png";
import earbuddyzUltra12BlackBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-banner-1.jpg";
import earbuddyzUltra12BlackBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-banner-2.jpg";
import earbuddyzUltra12BlackBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-banner-3.jpg";
import earbuddyzUltra12BlackPoster1 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-poster-1.jpg";
import earbuddyzUltra12BlackPoster2 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-poster-2.jpg";
import earbuddyzUltra12BlackPoster3 from "../../assets/img/products/products/Single Product/EarBuddyz Ultra/Black/eb-ultra-blk-poster-3.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-earbuddyzpro.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-earbuddyzultrapro.jpg";

const earbuddyzUltra12 = [
  {
    id: "earbuddyz-ultra-for-airpods-gen-1-2-white",
    name: "EarBuddyz ULTRA \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "EarBuddyz Ultra Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-ultra-for-airpods-gen-1-2-black",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [earbuddyzUltra12WiteImg1, earbuddyzUltra12WiteImg2, earbuddyzUltra12WiteImg3, earbuddyzUltra12WiteImg4],
    description:
      "EarBuddyz Ultra take the original EarBuddyz one step further by combining the comfort and security of our original hooks with improved noise isolation and overall sound performance. A spherical bud was designed to create a better seal to help keep unwanted noise out and the stuff that matters in. \n\n EarBuddyz Ultra are engineered from a premium dual durometer silicone which allows for maximum comfort without compromising a secure fit.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
    },
    banners: [earbuddyzUltra12WiteBanner1, earbuddyzUltra12WiteBanner2, earbuddyzUltra12WiteBanner3],
    posters: [earbuddyzUltra12WitePoster1, earbuddyzUltra12WitePoster2, earbuddyzUltra12WitePoster3],
    relatedProducts: [
      "earbuddyz-ultra-for-airpods-gen-1-2-black",
      "earbuddyz-ultra-for-airpods-pro",
      "earbuddyz-for-airpods-pro-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-for-airpods-pro-black",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
    },
  },

  {
    id: "earbuddyz-ultra-for-airpods-gen-1-2-black",
    name: "EarBuddyz ULTRA \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "EarBuddyz Ultra Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-ultra-for-airpods-gen-1-2-black",
      },
    ],
    selectedColor: "#202020",
    images: [
      earbuddyzUltra12BlackImg1,
      earbuddyzUltra12BlackImg2,
      earbuddyzUltra12BlackImg3,
      earbuddyzUltra12BlackImg4,
    ],
    description:
      "EarBuddyz Ultra take the original EarBuddyz one step further by combining the comfort and security of our original hooks with improved noise isolation and overall sound performance. A spherical bud was designed to create a better seal to help keep unwanted noise out and the stuff that matters in. \n\n EarBuddyz Ultra are engineered from a premium dual durometer silicone which allows for maximum comfort without compromising a secure fit.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
    },
    banners: [earbuddyzUltra12BlackBanner1, earbuddyzUltra12BlackBanner2, earbuddyzUltra12BlackBanner3],
    posters: [earbuddyzUltra12BlackPoster1, earbuddyzUltra12BlackPoster2, earbuddyzUltra12BlackPoster3],
    relatedProducts: [
      "earbuddyz-ultra-for-airpods-gen-1-2-white",
      "earbuddyz-ultra-for-airpods-pro",
      "earbuddyz-for-airpods-pro-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-for-airpods-pro-black",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
    },
  },
];

export default earbuddyzUltra12;

// Black
import earbuddyz12WiteImg1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-1.png";
import earbuddyz12WiteImg2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-2.png";
import earbuddyz12WiteImg3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-3.png";
import earbuddyz12WiteImg4 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-4.png";
import earbuddyz12WiteBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-banner-1.jpg";
import earbuddyz12WiteBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-banner-2.jpg";
import earbuddyz12WiteBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/White/eb-wht-banner-3.jpg";

// White
import earbuddyz12BlackImg1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-1.png";
import earbuddyz12BlackImg2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-2.png";
import earbuddyz12BlackImg3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-3.png";
import earbuddyz12BlackImg4 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-4.png";
import earbuddyz12BlackBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-banner-1.jpg";
import earbuddyz12BlackBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-banner-2.jpg";
import earbuddyz12BlackBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods/Black/eb-blk-banner-3.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-artisanpro.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-earbuddyzpro.jpg";

const earbuddyz12 = [
  {
    id: "earbuddyz-for-airpods-gen-1-2-white",
    name: "EarBuddyz \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "EarBuddyz Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-for-airpods-gen-1-2-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-for-airpods-gen-1-2-black",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [earbuddyz12WiteImg1, earbuddyz12WiteImg2, earbuddyz12WiteImg3, earbuddyz12WiteImg4],
    description:
      "EarBuddyz eliminate the fuss and hassle of constantly adjusting your AirPods & EarPods and provide an ultra secure listening experience. \n\n Engineered from high performance elastomers, EarBuddyz comfortably grip your ear and provide additional support by conforming to the groove of your inner ear. Experience twice the comfort and secure fit compared to using AirPods & EarPods alone, Buddy Up!",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-for-airpods-gen-1-2-white",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-for-airpods-pro-white",
      },
    },
    banners: [earbuddyz12WiteBanner1, earbuddyz12WiteBanner2, earbuddyz12WiteBanner3],
    posters: [],
    relatedProducts: [
      "earbuddyz-for-airpods-gen-1-2-black",
      "earbuddyz-for-airpods-pro-white",
      "earbuddyz-for-airpods-pro-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-pro-white",
      },
    },
  },

  {
    id: "earbuddyz-for-airpods-gen-1-2-black",
    name: "EarBuddyz \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "EarBuddyz Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-for-airpods-gen-1-2-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-for-airpods-gen-1-2-black",
      },
    ],
    selectedColor: "#202020",
    images: [earbuddyz12BlackImg1, earbuddyz12BlackImg2, earbuddyz12BlackImg3, earbuddyz12BlackImg4],
    description:
      "EarBuddyz eliminate the fuss and hassle of constantly adjusting your AirPods & EarPods and provide an ultra secure listening experience. \n\n Engineered from high performance elastomers, EarBuddyz comfortably grip your ear and provide additional support by conforming to the groove of your inner ear. Experience twice the comfort and secure fit compared to using AirPods & EarPods alone, Buddy Up!",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-for-airpods-pro-black",
      },
    },
    banners: [earbuddyz12BlackBanner1, earbuddyz12BlackBanner2, earbuddyz12BlackBanner3],
    posters: [],
    relatedProducts: [
      "earbuddyz-for-airpods-gen-1-2-white",
      "earbuddyz-for-airpods-pro-white",
      "earbuddyz-for-airpods-pro-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/artisan-series-airpods-pro-case-grey",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-for-airpods-pro-white",
      },
    },
  },
];

export default earbuddyz12;

import { createContext } from "react";
import { observable, action, makeObservable } from "mobx";

class GeneralStore {
  showWhiteNavbar = false;
  wishlist = [];

  constructor() {
    makeObservable(this, {
      showWhiteNavbar: observable,
      setShowWhiteNavbar: action,
      wishlist: observable,
      setWishlist: action,
    });

    const wishlistStorage = JSON.parse(sessionStorage.getItem("wishlist"));
    this.wishlist = wishlistStorage || [];
  }

  setShowWhiteNavbar(data) {
    this.showWhiteNavbar = data;
  }

  setWishlist(data) {
    this.wishlist = data;
    sessionStorage.setItem("wishlist", JSON.stringify(data));
  }
}

export default createContext(new GeneralStore());

import React from "react";
import PropTypes from "prop-types";
import styles from "./SocialItem.module.scss";
import img1 from "../assets/img/home/socials/insta-1-comp.jpg";
import img2 from "../assets/img/home/socials/insta-2-comp.jpg";
import img3 from "../assets/img/home/socials/insta-3-comp.jpg";
import img4 from "../assets/img/home/socials/insta-4-comp.jpg";
import img5 from "../assets/img/home/socials/insta-5-comp.jpg";
import ButtonBorder from "./ButtonBorder";

const SocialItem = ({ type }) => {
  const map = {
    insta1: {
      img: img1,
      title: "Artisan Series",
      text: "AirPods Pro Case",
    },
    insta2: {
      img: img2,
      title: "AirTags Keyring",
      text: "Leather Keyring for AirTags",
    },
    insta3: {
      img: img3,
      title: "Earbuddyz for AirPods Pro",
      text: "AirPods Performance Hooks",
    },
    insta4: {
      img: img4,
      title: "AirStrapz",
      text: "AirPods and AirPods Pro Straps",
    },
    insta5: {
      img: img5,
      title: "Elevate Series",
      text: "Case for AirPods 1/2",
    },
  };

  return (
    <div className={styles.imgDiv}>
      <img src={map[type].img} alt="favorite product" />

      <ButtonBorder
        className={styles.button}
        text="View Instagram"
        icon="arrow"
        showWhite
        onClick={() => {
          window.open("https://www.instagram.com/keybudz/", "_blank");
        }}
      />
    </div>
  );
};

SocialItem.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SocialItem;

import React, { useEffect, useState } from "react";
import cx from "classnames";
import Icon from "@material-ui/core/Icon";
import styles from "./SearchBox.module.scss";
import productsList from "../stores/allProductsList";
import { useHistory } from "react-router";

const SearchBox = ({ showWhite, isMobile }) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [searchedList, setSearchedList] = useState([]);

  useEffect(() => {
    if (searchValue) {
      const val = searchValue.toLowerCase(); // for turning off case sensitive
      const searched = productsList.filter((product) => product.name.toLowerCase().includes(val));
      setSearchedList(searched);
    } else {
      setSearchedList([]);
    }
  }, [searchValue]);
  return (
    <div className={cx(styles.searchDiv, showWhite && !showInput && styles.white)}>
      {isMobile ? (
        <>
          <input
            className={styles.mobileSearch}
            placeholder="search products"
            type="text"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />{" "}
          <Icon className={styles.searchIconMobile}>search</Icon>
        </>
      ) : (
        <>
          {showInput && (
            <input
              type="text"
              placeholder="search products"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          )}

          <Icon
            className={styles.searchIcon}
            onClick={() => {
              setShowInput(!showInput);
            }}
          >
            search
          </Icon>
        </>
      )}

      {(showInput || isMobile) && searchedList && searchedList.length > 0 && (
        <div className={cx(styles.searchedDiv, isMobile && styles.mobile)}>
          <ul>
            {searchedList.map((product) => (
              <li
                onClick={() => {
                  setShowInput(false);
                  setSearchValue("");
                  history.push(`/products/${product.id}`);
                }}
              >
                <img src={product.images[0]} alt={product.name} />
                <span>{product.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBox;

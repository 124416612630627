// Black
import earbuddyzProWiteImg1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-1.png";
import earbuddyzProWiteImg2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-2.png";
import earbuddyzProWiteImg3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-3.png";
import earbuddyzProWiteImg4 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-4.png";
import earbuddyzProWiteBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-banner-1.jpg";
import earbuddyzProWiteBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-banner-2.jpg";
import earbuddyzProWiteBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-banner-3.jpg";
import earbuddyzProWitePoster1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-poster-1.jpg";
import earbuddyzProWitePoster2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-poster-2.jpg";
import earbuddyzProWitePoster3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/White/eb-pro-wht-poster-3.jpg";

// White
import earbuddyzProBlackImg1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-1.png";
import earbuddyzProBlackImg2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-2.png";
import earbuddyzProBlackImg3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-3.png";
import earbuddyzProBlackImg4 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-4.png";
import earbuddyzProBlackBanner1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-banner-1.jpg";
import earbuddyzProBlackBanner2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-banner-2.jpg";
import earbuddyzProBlackBanner3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-banner-3.jpg";
import earbuddyzProBlackPoster1 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-poster-1.jpg";
import earbuddyzProBlackPoster2 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-poster-2.jpg";
import earbuddyzProBlackPoster3 from "../../assets/img/products/products/Single Product/EarBuddyz AirPods Pro/Black/eb-pro-blk-poster-3.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-earbuddyz.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-earbuddyzultra.jpg";

const earbuddyzPro = [
  {
    id: "earbuddyz-for-airpods-pro-white",
    name: "EarBuddyz \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Hooks",
    series: "EarBuddyz Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-for-airpods-pro-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-for-airpods-pro-black",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [earbuddyzProWiteImg1, earbuddyzProWiteImg2, earbuddyzProWiteImg3, earbuddyzProWiteImg4],
    description:
      "EarBuddyz for AirPods Pro provides a more secure listening experience for AirPods Pro users. Engineered from premium silicone elastomers to create a customized fit for all different sizes of ears. \n\n EarBuddyz for AirPods Pro comfortably grip your ear and provide additional support byconforming to the groove of your inner ear. Experience twice the comfort and secure fit compared to using AirPods Pro alone.",
    note: "*Antimicrobial properties are built in to inhibit the growth of bacteria that may affect this product. The antimicrobial properties do not protect users or others against bacteria, viruses, germs, or other disease organisms.",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-for-airpods-pro-black",
      },
    },
    banners: [earbuddyzProWiteBanner1, earbuddyzProWiteBanner2, earbuddyzProWiteBanner3],
    posters: [earbuddyzProWitePoster1, earbuddyzProWitePoster2, earbuddyzProWitePoster3],
    relatedProducts: [
      "earbuddyz-for-airpods-pro-black",
      "earbuddyz-for-airpods-gen-1-2-white",
      "earbuddyz-for-airpods-gen-1-2-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
    },
  },

  {
    id: "earbuddyz-for-airpods-pro-black",
    name: "EarBuddyz \n for AirPods Pro",
    device: "AirPods Pro",
    category: "AirPods Hooks",
    series: "EarBuddyz Series",
    colors: [
      {
        color: "#EFEFEF",
        url: "earbuddyz-for-airpods-pro-white",
      },
      {
        color: "#202020",
        url: "earbuddyz-for-airpods-pro-black",
      },
    ],
    selectedColor: "#202020",
    images: [earbuddyzProBlackImg1, earbuddyzProBlackImg2, earbuddyzProBlackImg3, earbuddyzProBlackImg4],
    description:
      "EarBuddyz for AirPods Pro provides a more secure listening experience for AirPods Pro users. Engineered from premium silicone elastomers to create a customized fit for all different sizes of ears. \n\n EarBuddyz for AirPods Pro comfortably grip your ear and provide additional support byconforming to the groove of your inner ear. Experience twice the comfort and secure fit compared to using AirPods Pro alone.",
    note: "*Antimicrobial properties are built in to inhibit the growth of bacteria that may affect this product. The antimicrobial properties do not protect users or others against bacteria, viruses, germs, or other disease organisms.",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/earbuddyz-for-airpods-pro-black",
      },
    },
    banners: [earbuddyzProBlackBanner1, earbuddyzProBlackBanner2, earbuddyzProBlackBanner3],
    posters: [earbuddyzProBlackPoster1, earbuddyzProBlackPoster2, earbuddyzProBlackPoster3],
    relatedProducts: [
      "earbuddyz-for-airpods-pro-white",
      "earbuddyz-for-airpods-gen-1-2-white",
      "earbuddyz-for-airpods-gen-1-2-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/earbuddyz-ultra-for-airpods-pro" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-for-airpods-gen-1-2-black",
      },
      next: {
        img: nextCategory,
        url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white",
      },
    },
  },
];

export default earbuddyzPro;

import React from "react";
import cx from "classnames";
import styles from "./Icon.module.scss";
import arrow from "../assets/img/icons/icon-arrow-button.svg";
import arrowWhite from "../assets/img/icons/icon-arrow-button-white.svg";
import cart from "../assets/img/icons/icon-cart.svg";
import cartWhite from "../assets/img/icons/icon-cart-white.svg";
import search from "../assets/img/icons/icon-search.svg";
import searchWhite from "../assets/img/icons/icon-search-white.svg";
import facebook from "../assets/img/icons/icon-facebook.svg";
import facebookWhite from "../assets/img/icons/icon-facebook-white.svg";
import instagram from "../assets/img/icons/icon-instagram.svg";
import instagramWhite from "../assets/img/icons/icon-instagram-white.svg";
import twitter from "../assets/img/icons/icon-twitter.svg";
import twitterWhite from "../assets/img/icons/icon-twitter-white.svg";

const Icon = ({
  className,
  icon,
  arrowLeft,
  arrowDown,
  onClick,
  showWhite,
}) => {
  const map = {
    arrow: showWhite ? arrowWhite : arrow,
    cart: showWhite ? cartWhite : cart,
    search: showWhite ? searchWhite : search,
    facebook: showWhite ? facebookWhite : facebook,
    instagram: showWhite ? instagramWhite : instagram,
    twitter: showWhite ? twitterWhite : twitter,
  };

  return (
    <img
      className={cx(
        className,
        arrowLeft && styles.rotateLeft,
        arrowDown && styles.rotateDown
      )}
      src={map[icon]}
      alt="icon"
      onClick={onClick}
    />
  );
};

export default Icon;

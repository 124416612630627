/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Button from "../../../components/Button";
import styles from "./Page404.module.scss";
import img404 from "../../../assets/img/404.jpg";
import GeneralStore from "../../../stores/GeneralStore";

const Page404 = () => {
  const generalStore = useContext(GeneralStore);

  useEffect(() => {
    generalStore.setShowWhiteNavbar(false);
  }, []);

  const clickHandler = () => {
    window.location.href = "/";
  };

  return (
    <div className={styles.wrapper}>
      <img src={img404} className={styles.image} alt="404 page" />

      <Button text={"Go to Home"} className={styles.button} onClick={clickHandler} />
    </div>
  );
};

export default Page404;

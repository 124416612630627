import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "./Icon";
import styles from "./Button.module.scss";

const Button = ({ text, onClick, className, icon, showWhite }) => {
  return (
    <button
      className={cx(styles.button, showWhite && styles.white, className)}
      onClick={onClick}
    >
      <span className="pr-3">{text}</span>
      {icon && <Icon showWhite={showWhite} icon={icon} />}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  icon: "",
};

export default Button;

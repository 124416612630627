// Black
import airdockzBlack1 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-1.png";
import airdockzBlack2 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-2.png";
import airdockzBlack3 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-3.png";
import airdockzBlack4 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-4.png";
import airdockzBannerBlack1 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-banner-1.jpg";
import airdockzBannerBlack2 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-banner-2.jpg";
import airdockzBannerBlack3 from "../../assets/img/products/products/Single Product/AirDockz/Black/airdockz-blk-banner-3.jpg";

// Cobalt Blue
import airdockzCobaltBlue1 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-1.png";
import airdockzCobaltBlue2 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-2.png";
import airdockzCobaltBlue3 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-3.png";
import airdockzCobaltBlue4 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-4.png";
import airdockzBannerCobaltBlue1 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-banner-1.jpg";
import airdockzBannerCobaltBlue2 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-banner-2.jpg";
import airdockzBannerCobaltBlue3 from "../../assets/img/products/products/Single Product/AirDockz/Cobalt Blue/airdockz-cbb-banner-3.jpg";

// Pink
import airdockzPink1 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-1.png";
import airdockzPink2 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-2.png";
import airdockzPink3 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-3.png";
import airdockzPink4 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-4.png";
import airdockzBannerPink1 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-banner-1.jpg";
import airdockzBannerPink2 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-banner-2.jpg";
import airdockzBannerPink3 from "../../assets/img/products/products/Single Product/AirDockz/Blush Pink/airdockz-bpk-banner-3.jpg";

// White
import airdockzWhite1 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-1.png";
import airdockzWhite2 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-2.png";
import airdockzWhite3 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-3.png";
import airdockzWhite4 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-4.png";
import airdockzBannerWhite1 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-banner-1.jpg";
import airdockzBannerWhite2 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-banner-2.jpg";
import airdockzBannerWhite3 from "../../assets/img/products/products/Single Product/AirDockz/White/airdockz-wht-banner-3.jpg";

import bottomBannerBlack from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-elevatepro.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-airlockz.jpg";

const airdockz = [
  // Black
  {
    id: "airdockz-dock-for-airpods-black",
    name: "AirDockz \n Dock for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airdockz-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airdockz-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airdockz-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airdockz-dock-for-airpods-white",
      },
    ],
    selectedColor: "#202020",
    images: [airdockzBlack1, airdockzBlack2, airdockzBlack3, airdockzBlack4],
    description:
      "AirDockz provide a convenient dock to place your AirPods when not in use. Featuring a magnetic base, the AirDockz keep AirPods secured in the office, car, or virtually anywhere you use your AirPods. \n\n A restickable adhesive strip allows you to restick the AirDockz onto any surface over and over again. Never lose track of your AirPods again.",
    note: "",
    banners: [airdockzBannerBlack1, airdockzBannerBlack2, airdockzBannerBlack3],
    posters: [],
    relatedProducts: [
      "airdockz-dock-for-airpods-cobalt-blue",
      "airdockz-dock-for-airpods-pink",
      "airdockz-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
    },
  },

  // Cobalt blue
  {
    id: "airdockz-dock-for-airpods-cobalt-blue",
    name: "AirDockz \n Dock for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airdockz-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airdockz-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airdockz-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airdockz-dock-for-airpods-white",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [airdockzCobaltBlue1, airdockzCobaltBlue2, airdockzCobaltBlue3, airdockzCobaltBlue4],
    description:
      "AirDockz provide a convenient dock to place your AirPods when not in use. Featuring a magnetic base, the AirDockz keep AirPods secured in the office, car, or virtually anywhere you use your AirPods. \n\n A restickable adhesive strip allows you to restick the AirDockz onto any surface over and over again. Never lose track of your AirPods again.",
    note: "",
    banners: [airdockzBannerCobaltBlue1, airdockzBannerCobaltBlue2, airdockzBannerCobaltBlue3],
    posters: [],
    relatedProducts: [
      "airdockz-dock-for-airpods-black",
      "airdockz-dock-for-airpods-pink",
      "airdockz-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
    },
  },

  // Pink
  {
    id: "airdockz-dock-for-airpods-pink",
    name: "AirDockz \n Dock for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airdockz-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airdockz-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airdockz-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airdockz-dock-for-airpods-white",
      },
    ],
    selectedColor: "#FFB5CA",
    images: [airdockzPink1, airdockzPink2, airdockzPink3, airdockzPink4],
    description:
      "AirDockz provide a convenient dock to place your AirPods when not in use. Featuring a magnetic base, the AirDockz keep AirPods secured in the office, car, or virtually anywhere you use your AirPods. \n\n A restickable adhesive strip allows you to restick the AirDockz onto any surface over and over again. Never lose track of your AirPods again.",
    note: "",
    banners: [airdockzBannerPink1, airdockzBannerPink2, airdockzBannerPink3],
    posters: [],
    relatedProducts: [
      "airdockz-dock-for-airpods-black",
      "airdockz-dock-for-airpods-cobalt-blue",
      "airdockz-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
    },
  },

  // White
  {
    id: "airdockz-dock-for-airpods-white",
    name: "AirDockz \n Dock for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airdockz-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airdockz-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airdockz-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airdockz-dock-for-airpods-white",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [airdockzWhite1, airdockzWhite2, airdockzWhite3, airdockzWhite4],
    description:
      "AirDockz provide a convenient dock to place your AirPods when not in use. Featuring a magnetic base, the AirDockz keep AirPods secured in the office, car, or virtually anywhere you use your AirPods. \n\n A restickable adhesive strip allows you to restick the AirDockz onto any surface over and over again. Never lose track of your AirPods again.",
    note: "",
    banners: [airdockzBannerWhite1, airdockzBannerWhite2, airdockzBannerWhite3],
    posters: [],
    relatedProducts: [
      "airdockz-dock-for-airpods-black",
      "airdockz-dock-for-airpods-cobalt-blue",
      "airdockz-dock-for-airpods-pink",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
    },
  },
];

export default airdockz;

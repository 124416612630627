import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./ProductsFilterItem.module.scss";

const ProductsFilterItem = ({
  title,
  categories,
  selectedCategory,
  onSelectCategory,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
      </div>

      <div className={styles.content}>
        {categories.map((category) => (
          <p
            className={cx(
              selectedCategory === category?.name && styles.selected,
              category.color && styles.color
            )}
            key={category.id}
            onClick={() => {
              onSelectCategory(category.color || category.name);
            }}
          >
            {category.color && (
              <span
                className={styles.colorSpan}
                style={{ backgroundColor: category.color }}
              />
            )}
            {category.name}{" "}
            {category.count && category.count > 0 && (
              <span className="ml-1">({category.count})</span>
            )}
          </p>
        ))}
      </div>
    </div>
  );
};

ProductsFilterItem.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.array,
  onSelectCategory: PropTypes.func,
  selectedCategory: PropTypes.string,
};

export default ProductsFilterItem;

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Products from "../pages/Products/Products";
import Wishlist from "../pages/Products/Wishlist";
import SingleProduct from "../pages/Products/SingleProduct";
import Page404 from "../pages/errors/404/Page404";
import ScrollToTop from "../shared/ScrollToTop";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Route {...rest} component={Component} />
      <Footer />
    </>
  );
};

export const AppRouter = () => (
  <Router>
    <Switch>
      <PublicRoute exact path="/about" component={About} />
      <PublicRoute exact path="/products" component={Products} />
      <PublicRoute exact path="/wishlist" component={Wishlist} />
      <PublicRoute exact path="/products/:id" component={SingleProduct} />
      <PublicRoute exact path="/home" component={Home} />
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/404" component={Page404} />
      <PublicRoute exact path="*" component={Page404} />
    </Switch>
  </Router>
);

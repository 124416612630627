// Baby blue
import elevate12BabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-1.png";
import elevate12BabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-2.png";
import elevate12BabyBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-3.png";
import elevate12BabyBlue4 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-4.png";
import elevate12BannerBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-banner-1.jpg";
import elevate12BannerBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-banner-2.jpg";
import elevate12BannerBabyBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-banner-3.jpg";
import elevate12PosterBabyBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-poster-1.jpg";
import elevate12PosterBabyBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-poster-2.jpg";
import elevate12PosterBabyBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Baby Blue/elevate-bbl-poster-3.jpg";

// Black
import elevate12Black1 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-1.png";
import elevate12Black2 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-2.png";
import elevate12Black3 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-3.png";
import elevate12Black4 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-4.png";
import elevate12BannerBlack1 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-banner-1.jpg";
import elevate12BannerBlack2 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-banner-2.jpg";
import elevate12BannerBlack3 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-banner-3.jpg";
import elevate12PosterBlack1 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-poster-1.jpg";
import elevate12PosterBlack2 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-poster-2.jpg";
import elevate12PosterBlack3 from "../../assets/img/products/products/Single Product/ELEVATE/Black/elevate-blk-poster-3.jpg";

// Cobalt Blue
import elevate12CobalBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-1.png";
import elevate12CobalBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-2.png";
import elevate12CobalBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-3.png";
import elevate12CobalBlue4 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-4.png";
import elevate12BannerCobalBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-banner-1.jpg";
import elevate12BannerCobalBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-banner-2.jpg";
import elevate12BannerCobalBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-banner-3.jpg";
import elevate12PosterCobalBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-poster-1.jpg";
import elevate12PosterCobalBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-poster-2.jpg";
import elevate12PosterCobalBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Cobalt Blue/elevate-cbb-poster-3.jpg";

// Diamobd Blue
import elevate12DiamondBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-1.png";
import elevate12DiamondBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-2.png";
import elevate12DiamondBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-3.png";
import elevate12DiamondBlue4 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-4.png";
import elevate12BannerDiamondBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-banner-1.jpg";
import elevate12BannerDiamondBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-banner-2.jpg";
import elevate12BannerDiamondBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-banner-3.jpg";
import elevate12PosterDiamondBlue1 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-poster-1.jpg";
import elevate12PosterDiamondBlue2 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-poster-2.jpg";
import elevate12PosterDiamondBlue3 from "../../assets/img/products/products/Single Product/ELEVATE/Diamond Blue/elevate-dmb-poster-3.jpg";

// Grey
import elevate12Grey1 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-1.png";
import elevate12Grey2 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-2.png";
import elevate12Grey3 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-3.png";
import elevate12Grey4 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-4.png";
import elevate12BannerGrey1 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-banner-1.jpg";
import elevate12BannerGrey2 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-banner-2.jpg";
import elevate12BannerGrey3 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-banner-3.jpg";
import elevate12PosterGrey1 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-poster-1.jpg";
import elevate12PosterGrey2 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-poster-2.jpg";
import elevate12PosterGrey3 from "../../assets/img/products/products/Single Product/ELEVATE/Grey/elevate-gry-poster-3.jpg";

// Red
import elevate12Red1 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-1.png";
import elevate12Red2 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-2.png";
import elevate12Red3 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-3.png";
import elevate12Red4 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-4.png";
import elevate12BannerRed1 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-banner-1.jpg";
import elevate12BannerRed2 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-banner-2.jpg";
import elevate12BannerRed3 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-banner-3.jpg";
import elevate12PosterRed1 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-poster-1.jpg";
import elevate12PosterRed2 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-poster-2.jpg";
import elevate12PosterRed3 from "../../assets/img/products/products/Single Product/ELEVATE/Red/elevate-red-poster-3.jpg";

// Lavender
import elevate12Lavender1 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-1.png";
import elevate12Lavender2 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-2.png";
import elevate12Lavender3 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-3.png";
import elevate12Lavender4 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-4.png";
import elevate12BannerLavender1 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-banner-1.jpg";
import elevate12BannerLavender2 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-banner-2.jpg";
import elevate12BannerLavender3 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-banner-3.jpg";
import elevate12PosterLavender1 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-poster-1.jpg";
import elevate12PosterLavender2 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-poster-2.jpg";
import elevate12PosterLavender3 from "../../assets/img/products/products/Single Product/ELEVATE/Lavender/elevate-lav-poster-3.jpg";

// Pink
import elevate12Pink1 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-1.png";
import elevate12Pink2 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-2.png";
import elevate12Pink3 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-3.png";
import elevate12Pink4 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-4.png";
import elevate12BannerPink1 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-banner-1.jpg";
import elevate12BannerPink2 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-banner-2.jpg";
import elevate12BannerPink3 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-banner-3.jpg";
import elevate12PosterPink1 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-poster-1.jpg";
import elevate12PosterPink2 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-poster-2.jpg";
import elevate12PosterPink3 from "../../assets/img/products/products/Single Product/ELEVATE/Blush Pink/elevate-bpn-poster-3.jpg";

// White
import elevate12White1 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-1.png";
import elevate12White2 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-2.png";
import elevate12White3 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-3.png";
import elevate12White4 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-4.png";
import elevate12BannerWhite1 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-banner-1.jpg";
import elevate12BannerWhite2 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-banner-2.jpg";
import elevate12BannerWhite3 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-banner-3.jpg";
import elevate12PosterWhite1 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-poster-1.jpg";
import elevate12PosterWhite2 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-poster-2.jpg";
import elevate12PosterWhite3 from "../../assets/img/products/products/Single Product/ELEVATE/White/elevate-wht-poster-3.jpg";

// Yellow
import elevate12Yellow1 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-1.png";
import elevate12Yellow2 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-2.png";
import elevate12Yellow3 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-3.png";
import elevate12Yellow4 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-4.png";
import elevate12BannerYellow1 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-banner-1.jpg";
import elevate12BannerYellow2 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-banner-2.jpg";
import elevate12BannerYellow3 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-banner-3.jpg";
import elevate12PosterYellow1 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-poster-1.jpg";
import elevate12PosterYellow2 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-poster-2.jpg";
import elevate12PosterYellow3 from "../../assets/img/products/products/Single Product/ELEVATE/Pastel Yellow/elevate-pyl-poster-3.jpg";

import bottomBanner from "../../assets/img/products/products/Banners Random/Banner - Artisan Pro - Red.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-earbuddyzultrapro.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-elevateswarovski.jpg";

const elevate12 = [
  // Baby blue
  {
    id: "elevate-series-for-airpods-gen-1-2-baby-blue",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods Gen 1/2  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#A2C3E9",
    images: [elevate12BabyBlue1, elevate12BabyBlue2, elevate12BabyBlue3, elevate12BabyBlue4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-baby-blue",
      },
    },
    banners: [elevate12BannerBabyBlue1, elevate12BannerBabyBlue2, elevate12BannerBabyBlue3],
    posters: [elevate12PosterBabyBlue1, elevate12PosterBabyBlue2, elevate12PosterBabyBlue3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-black",
      "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      "elevate-series-for-airpods-gen-1-2-diamond-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Black
  {
    id: "elevate-series-for-airpods-gen-1-2-black",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods Gen 1/2  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#202020",
    images: [elevate12Black1, elevate12Black2, elevate12Black3, elevate12Black4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-black",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-black",
      },
    },
    banners: [elevate12BannerBlack1, elevate12BannerBlack2, elevate12BannerBlack3],
    posters: [elevate12PosterBlack1, elevate12PosterBlack2, elevate12PosterBlack3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-baby-blue",
      "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      "elevate-series-for-airpods-gen-1-2-diamond-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Cobalt Blue
  {
    id: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods Gen 1/2  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [elevate12CobalBlue1, elevate12CobalBlue2, elevate12CobalBlue3, elevate12CobalBlue4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-cobalt-blue",
      },
    },
    banners: [elevate12BannerCobalBlue1, elevate12BannerCobalBlue2, elevate12BannerCobalBlue3],
    posters: [elevate12PosterCobalBlue1, elevate12PosterCobalBlue2, elevate12PosterCobalBlue3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-black",
      "elevate-series-for-airpods-gen-1-2-diamond-blue",
      "elevate-series-for-airpods-gen-1-2-grey",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Diamond Blue
  {
    id: "elevate-series-for-airpods-gen-1-2-diamond-blue",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods Gen 1/2  ",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#6CE8D8",
    images: [elevate12DiamondBlue1, elevate12DiamondBlue2, elevate12DiamondBlue3, elevate12DiamondBlue4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-diamond-blue",
      },
    },
    banners: [elevate12BannerDiamondBlue1, elevate12BannerDiamondBlue2, elevate12BannerDiamondBlue3],
    posters: [elevate12PosterDiamondBlue1, elevate12PosterDiamondBlue2, elevate12PosterDiamondBlue3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      "elevate-series-for-airpods-gen-1-2-grey",
      "elevate-series-for-airpods-gen-1-2-red",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Grey
  {
    id: "elevate-series-for-airpods-gen-1-2-grey",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#B1BDC2",
    images: [elevate12Grey1, elevate12Grey2, elevate12Grey3, elevate12Grey4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-grey",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-grey",
      },
    },
    banners: [elevate12BannerGrey1, elevate12BannerGrey2, elevate12BannerGrey3],
    posters: [elevate12PosterGrey1, elevate12PosterGrey2, elevate12PosterGrey3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-diamond-blue",
      "elevate-series-for-airpods-gen-1-2-red",
      "elevate-series-for-airpods-gen-1-2-lavender",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Red
  {
    id: "elevate-series-for-airpods-gen-1-2-red",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#E14242",
    images: [elevate12Red1, elevate12Red2, elevate12Red3, elevate12Red4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-red",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-red",
      },
    },
    banners: [elevate12BannerRed1, elevate12BannerRed2, elevate12BannerRed3],
    posters: [elevate12PosterRed1, elevate12PosterRed2, elevate12PosterRed3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-grey",
      "elevate-series-for-airpods-gen-1-2-lavender",
      "elevate-series-for-airpods-gen-1-2-pink",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Lavender
  {
    id: "elevate-series-for-airpods-gen-1-2-lavender",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#BB9EFF",
    images: [elevate12Lavender1, elevate12Lavender2, elevate12Lavender3, elevate12Lavender4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-lavender",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-lavender",
      },
    },
    banners: [elevate12BannerLavender1, elevate12BannerLavender2, elevate12BannerLavender3],
    posters: [elevate12PosterLavender1, elevate12PosterLavender2, elevate12PosterLavender3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-red",
      "elevate-series-for-airpods-gen-1-2-pink",
      "elevate-series-for-airpods-gen-1-2-white",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Pink
  {
    id: "elevate-series-for-airpods-gen-1-2-pink",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#FFB5CA",
    images: [elevate12Pink1, elevate12Pink2, elevate12Pink3, elevate12Pink4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-pink",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-pink",
      },
    },
    banners: [elevate12BannerPink1, elevate12BannerPink2, elevate12BannerPink3],
    posters: [elevate12PosterPink1, elevate12PosterPink2, elevate12PosterPink3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-lavender",
      "elevate-series-for-airpods-gen-1-2-white",
      "elevate-series-for-airpods-gen-1-2-yellow",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // White
  {
    id: "elevate-series-for-airpods-gen-1-2-white",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [elevate12White1, elevate12White2, elevate12White3, elevate12White4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-white",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-white",
      },
    },
    banners: [elevate12BannerWhite1, elevate12BannerWhite2, elevate12BannerWhite3],
    posters: [elevate12PosterWhite1, elevate12PosterWhite2, elevate12PosterWhite3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-pink",
      "elevate-series-for-airpods-gen-1-2-yellow",
      "elevate-series-for-airpods-gen-1-2-baby-blue",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },

  // Yellow
  {
    id: "elevate-series-for-airpods-gen-1-2-yellow",
    name: "Elevate Series \n for AirPods Gen 1/2",
    device: "AirPods",
    category: "AirPods Cases",
    series: "Elevate Series",
    colors: [
      {
        color: "#A2C3E9",
        url: "elevate-series-for-airpods-gen-1-2-baby-blue",
      },
      {
        color: "#202020",
        url: "elevate-series-for-airpods-gen-1-2-black",
      },
      {
        color: "#4D5F7E",
        url: "elevate-series-for-airpods-gen-1-2-cobalt-blue",
      },
      {
        color: "#6CE8D8",
        url: "elevate-series-for-airpods-gen-1-2-diamond-blue",
      },
      {
        color: "#B1BDC2",
        url: "elevate-series-for-airpods-gen-1-2-grey",
      },
      {
        color: "#E14242",
        url: "elevate-series-for-airpods-gen-1-2-red",
      },
      {
        color: "#BB9EFF",
        url: "elevate-series-for-airpods-gen-1-2-lavender",
      },
      {
        color: "#FFB5CA",
        url: "elevate-series-for-airpods-gen-1-2-pink",
      },
      {
        color: "#EFEFEF",
        url: "elevate-series-for-airpods-gen-1-2-white",
      },
      {
        color: "#E9D5A7",
        url: "elevate-series-for-airpods-gen-1-2-yellow",
      },
    ],
    selectedColor: "#E9D5A7",
    images: [elevate12Yellow1, elevate12Yellow2, elevate12Yellow3, elevate12Yellow4],
    description:
      "Think of him as your ace, your sidekick,your buddy & pal. This little guy protects againts the bumps & bruises of the daily grind. And don’t let his soft side fool you, he’s actually as tough as nails. \n\n Ok well maybe not nails, but youget the idea. \n\n Elevate is ingineered from durable and impact absorbent elastomer to provide the perfect balance of lightweight protection and fit. So go right ahead and adventure on, he’ll keep your AirPods covered wherever you go.",
    note: "",
    deviceSelection: {
      normal: {
        img: null,
        url: "/products/elevate-series-for-airpods-gen-1-2-yellow",
      },
      pro: {
        img: null,
        url: "/products/elevate-series-airpods-pro-case-yellow",
      },
    },
    banners: [elevate12BannerYellow1, elevate12BannerYellow2, elevate12BannerYellow3],
    posters: [elevate12PosterYellow1, elevate12PosterYellow2, elevate12PosterYellow3],
    relatedProducts: [
      "elevate-series-for-airpods-gen-1-2-white",
      "elevate-series-for-airpods-gen-1-2-baby-blue",
      "elevate-series-for-airpods-gen-1-2-black",
    ],
    bottomBanner: { img: bottomBanner, url: "/products/artisan-series-airpods-pro-case-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/earbuddyz-ultra-for-airpods-pro",
      },
      next: {
        img: nextCategory,
        url: "/products/elevate-swarovski-for-airpods-pro",
      },
    },
  },
];

export default elevate12;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import styles from "./SingleProduct.module.scss";
import ButtonBorder from "../../components/ButtonBorder";
import eImg from "../../assets/img/about/e.png";
import allProductsList from "../../stores/allProductsList";
import Slider from "react-slick";
import GeneralStore from "../../stores/GeneralStore";
import randomBanners from "../../stores/randomBanners";
import Icon from "../../components/Icon";

const SingleProduct = () => {
  const generalStore = useContext(GeneralStore);
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    name: "",
    device: "",
    category: "",
    series: "",
    colors: [],
    selectedColor: "",
    images: [],
    description: "",
    note: "",
    banners: [],
    posters: [],
    insideImages: [],
    relatedProducts: [],
    bottomBanner: {
      img: null,
      url: "",
    },
    relatedCategory: {
      prev: {
        img: null,
        url: "",
      },
      next: {
        img: null,
        url: "",
      },
    },
  });
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState({ img: null, url: "", button: "black" });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const sliderProductRef = useRef();
  const settingsProducts = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLoading(true);

    const productId = history?.location?.pathname.slice(10);
    const selProd = allProductsList.filter((el) => el.id === productId)[0];
    if (selProd) {
      setSelectedProduct(selProd);
      setSelectedColor(selProd?.selectedColor || "");
      setSelectedImg(selProd?.images[0] || null);

      // Related Products
      const relArr = [];
      selProd.relatedProducts.forEach((elem) => {
        const prod = allProductsList.filter((el) => el.id === elem)[0];

        if (prod) {
          relArr.push(prod);
        }
      });
      setRelatedProducts(relArr);

      //Random Banner
      const maxNum = randomBanners.length;
      const ranNum = Math.floor(Math.random() * maxNum) + 1;
      setSelectedBanner(randomBanners[ranNum - 1]);
    }

    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, [JSON.stringify(history.location.pathname)]);

  useEffect(() => {
    generalStore.setShowWhiteNavbar(false);
  }, []);

  return (
    <>
      {loading && (
        <div className={styles.loading}>
          <div className={styles.bar} />
        </div>
      )}

      <div className={cx(styles.wrapper, "single-product-page")}>
        <section className={styles.productInfoSection}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={styles.infoDivBg}>
                  <div className="row">
                    <div className="col-12 d-block d-sm-none">
                      <div className={styles.textDiv}>
                        <p className={styles.device}>{selectedProduct.device}</p>
                        <h1 className={styles.title}>{selectedProduct.name}</h1>

                        {selectedProduct?.colors && selectedProduct?.colors.length > 0 && (
                          <div className={styles.colorsDiv}>
                            {selectedProduct.colors.map((color) => (
                              <div
                                key={color.color}
                                className={cx(
                                  styles.color,
                                  color.color.toLowerCase() === selectedColor.toLowerCase() && styles.active
                                )}
                                style={{ backgroundColor: color.color }}
                                onClick={() => {
                                  history.push(`/products/${color.url}`);
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-xl-6">
                      <div className={styles.imagesDiv}>
                        <div className={styles.selectedImage}>
                          <img src={selectedImg} alt="Product" />
                        </div>

                        <div className={styles.allImages}>
                          {selectedProduct.images.map((img) => (
                            <div
                              key={JSON.stringify(img)}
                              className={cx(styles.imageDiv, selectedImg === img && styles.active)}
                              onClick={() => {
                                setSelectedImg(img);
                              }}
                            >
                              <div className={styles.image}>
                                <img src={img} alt="Product" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-xl-6">
                      <div className={styles.textDiv}>
                        <div className="d-none d-sm-block">
                          <p className={styles.device}>{selectedProduct.device}</p>
                          <h1 className={styles.title}>{selectedProduct.name}</h1>

                          {selectedProduct?.colors && selectedProduct?.colors.length > 0 && (
                            <div className={styles.colorsDiv}>
                              {selectedProduct.colors.map((color) => (
                                <div
                                  key={color.color}
                                  className={cx(
                                    styles.color,
                                    color.color.toLowerCase() === selectedColor.toLowerCase() && styles.active
                                  )}
                                  style={{ backgroundColor: color.color }}
                                  onClick={() => {
                                    history.push(`/products/${color.url}`);
                                  }}
                                />
                              ))}
                            </div>
                          )}
                        </div>

                        <p className={styles.description}>{selectedProduct.description}</p>

                        <p className={styles.note}>{selectedProduct.note}</p>

                        {selectedProduct.deviceSelection && (
                          <>
                            <h5 className="mt-4">Device version</h5>
                            <div className={styles.deviceVersion}>
                              <div
                                onClick={() => {
                                  history.push(selectedProduct.deviceSelection.normal.url);
                                }}
                              >
                                AirPods
                              </div>{" "}
                              <div
                                onClick={() => {
                                  history.push(selectedProduct.deviceSelection.pro.url);
                                }}
                              >
                                AirPod Pro
                              </div>
                            </div>
                          </>
                        )}

                        <ButtonBorder
                          className={styles.button}
                          text="Explore"
                          showBorder
                          arrowDown
                          icon="arrow"
                          onClick={() => {
                            document.getElementById("banner-2").scrollIntoView();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.bannersSection}>
          <div className="container">
            <div className="row">
              {selectedProduct.banners.map((banner, index) => (
                <div key={JSON.stringify(banner)} className="col-12">
                  <div id={`banner-${index}`} className={styles.bannerDiv}>
                    <img src={banner} alt="banner" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={cx(styles.postersSection, selectedProduct.posters.length === 0 && styles.noBanners)}>
          <div className="container">
            <div className="row">
              {selectedProduct.posters.map((poster) => (
                <div key={JSON.stringify(poster)} className="col-12 col-md-4 mb-3">
                  <div className={styles.posterDiv}>
                    <img src={poster} alt="poster" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {selectedProduct.insideImages && selectedProduct.insideImages.length > 0 && (
          <section className={styles.insideSection}>
            <div className="container">
              <h1 className="section-title">What’s inside</h1>
              <div className="row">
                {selectedProduct.insideImages.map((img, index) => (
                  <div
                    key={JSON.stringify(img)}
                    className={cx(
                      "col-12",
                      index === selectedProduct.insideImages.length - 1 ? "col-md-6" : "col-md-3"
                    )}
                  >
                    <div className={styles.insideDiv}>
                      <img src={img} alt="inside img" />
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.grayBg} />
            </div>
          </section>
        )}

        <section className={styles.relatedProductsSection}>
          <div className="container">
            <h1 className="section-title mb-6">Related Products</h1>

            <div className={styles.sliderWrapper}>
              <Icon
                className={styles.arrowLeft}
                icon="arrow"
                onClick={() => {
                  sliderProductRef.current.slickPrev();
                }}
              />

              <Icon
                className={styles.arrowRight}
                icon="arrow"
                onClick={() => {
                  sliderProductRef.current.slickNext();
                }}
              />

              <Slider className="products-slider" {...settingsProducts} ref={sliderProductRef}>
                {relatedProducts.map((product) => (
                  <div key={product.id} className={styles.sliderDiv}>
                    <div className={styles.centerDiv}>
                      <h5 className={styles.title}>{product.name}</h5>

                      <div className={styles.imgDiv}>
                        <img src={product.images[0]} alt={product.name} />

                        <ButtonBorder
                          className={styles.button}
                          text="Explore Now"
                          icon="arrow"
                          onClick={() => {
                            history.push(`/products/${product.id}`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>

        <section
          className={styles.bottomBanner}
          onClick={() => {
            history.push(selectedBanner?.url);
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 position-relative">
                <img src={selectedBanner?.img} alt="bottom banner" />

                <div className={styles.bottomBannerDiv}>
                  <ButtonBorder
                    text="Explore Now"
                    showBorder
                    icon="arrow"
                    showWhite={selectedBanner.button !== "black"}
                    colorWhite={selectedBanner.button !== "black"}
                    onClick={() => {
                      history.push(selectedBanner?.url);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.aboutSection}>
          <div className="container">
            <div className="row position-relative">
              <img className={styles.eBg} src={eImg} alt="e bg" />

              <div className="col-12 col-lg-4 offset-lg-2">
                <h1 className={cx("section-title", styles.aboutTitle)}>About KeyBudz</h1>
              </div>

              <div className="col-12 col-lg-4">
                <p>
                  We've all been there,.. the never-ever land of constantly pushing in, twisting and relentless
                  readjusting of our Apple AirPods & Earpods.
                  <br />
                  <br />
                  EarBuddyz AirPods ear hooks eliminate the fuss and hassle of constantly adjusting your Apple earphones
                  and provide an ultra secure listening experience.
                  <br />
                  <br />
                  Engineered from high performance elastomers, EarBuddyz AirPods Hooks comfortably grip your ear and
                  provide additional support by conforming to the groove of your inner ear. Experience twice the comfort
                  and secure fit compared to using Apple earphones alone, Buddy Up!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.prevNextSection}>
          <div className="container">
            <div className="row">
              <div
                className="col-6 cursor-pointer"
                onClick={() => {
                  history.push(selectedProduct.relatedCategory.prev.url);
                }}
              >
                <p className={styles.navigation}>Prev Category</p>

                <div className={styles.categoryDiv}>
                  <img src={selectedProduct.relatedCategory.prev.img} alt="prev category" />
                </div>
              </div>

              <div
                className="col-6 cursor-pointer"
                onClick={() => {
                  history.push(selectedProduct.relatedCategory.next.url);
                }}
              >
                <p className={cx(styles.navigation, styles.navigationNext)}>Next Category</p>

                <div className={styles.categoryDiv}>
                  <img src={selectedProduct.relatedCategory.next.img} alt="next category" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SingleProduct;

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "@material-ui/core/Icon";
import styles from "./ShopProductItem.module.scss";

const ShopProductItem = ({ product, displayFormat, onAddToWishlist }) => {
  const history = useHistory();

  const onClickHandler = () => {
    history.push(`/products/${product.id}`);
  };

  return (
    <>
      {displayFormat === "dots" ? (
        <div className={styles.wrapper} onClick={onClickHandler}>
          <div className={styles.infoDiv}>
            <p className={styles.subtitle}>{product.device}</p>
            <h1 className={styles.title}>{product.name}</h1>
          </div>

          <div className={styles.productImgDiv}>
            <img src={product.images[0]} alt={product.name} />
          </div>

          <div className={styles.bottomDiv}>
            <Icon
              className={styles.heart}
              onClick={(event) => {
                event.stopPropagation();
                onAddToWishlist(product);
              }}
            >
              {product.wishlisted ? "favorite" : "favorite_border"}
            </Icon>

            <div className={styles.colorsDiv}>
              {product.colors.map((color) => (
                <div
                  key={color.color}
                  className={cx(styles.color, color.color === product.selectedColor && styles.active)}
                  style={{ backgroundColor: color.color }}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cx(styles.wrapper, styles.wrapperList, "d-flex justify-content-start align-items-start")}
          onClick={onClickHandler}
        >
          <div className={styles.productImgDiv}>
            <img src={product.images[0]} alt={product.name} />
          </div>

          <div className={styles.infoDiv}>
            <p className={styles.subtitle}>{product.device}</p>
            <h1 className={styles.title}>{product.name}</h1>
            <p className={styles.description}>{product.description}</p>
          </div>

          <div className={styles.bottomDiv}>
            <Icon
              className={styles.heart}
              onClick={(event) => {
                event.stopPropagation();
                onAddToWishlist(product);
              }}
            >
              {product.wishlisted ? "favorite" : "favorite_border"}
            </Icon>

            <div className={styles.colorsDiv}>
              {product.colors.map((color) => (
                <div
                  key={color.color}
                  className={cx(styles.color, color.color === product.selectedColor && styles.active)}
                  style={{ backgroundColor: color.color }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ShopProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  displayFormat: PropTypes.string.isRequired,
  onAddToWishlist: PropTypes.func.isRequired,
};

export default ShopProductItem;

// Black
import airstrapzBlack1 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-1.png";
import airstrapzBlack2 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-2.png";
import airstrapzBlack3 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-3.png";
import airstrapzBlack4 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-4.png";
import airstrapzBannerBlack1 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-banner-1.jpg";
import airstrapzBannerBlack2 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-banner-2.jpg";
import airstrapzBannerBlack3 from "../../assets/img/products/products/Single Product/AirStrapz/Black/airstrapz-blk-banner-3.jpg";

// Cobalt Blue
import airstrapzCobaltBlue1 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-1.png";
import airstrapzCobaltBlue2 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-2.png";
import airstrapzCobaltBlue3 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-3.png";
import airstrapzCobaltBlue4 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-4.png";
import airstrapzBannerCobaltBlue1 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-banner-1.jpg";
import airstrapzBannerCobaltBlue2 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-banner-2.jpg";
import airstrapzBannerCobaltBlue3 from "../../assets/img/products/products/Single Product/AirStrapz/Cobalt Blue/airstrapz-cbb-banner-3.jpg";

// Pink
import airstrapzPink1 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-1.png";
import airstrapzPink2 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-2.png";
import airstrapzPink3 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-3.png";
import airstrapzPink4 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-4.png";
import airstrapzBannerPink1 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-banner-1.jpg";
import airstrapzBannerPink2 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-banner-2.jpg";
import airstrapzBannerPink3 from "../../assets/img/products/products/Single Product/AirStrapz/Blush Pink/airstrapz-bpk-banner-3.jpg";

// White
import airstrapzWhite1 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-1.png";
import airstrapzWhite2 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-2.png";
import airstrapzWhite3 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-3.png";
import airstrapzWhite4 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-4.png";
import airstrapzBannerWhite1 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-banner-1.jpg";
import airstrapzBannerWhite2 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-banner-2.jpg";
import airstrapzBannerWhite3 from "../../assets/img/products/products/Single Product/AirStrapz/White/airstrapz-wht-banner-3.jpg";

import bottomBannerBlack from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-airlockz.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-aircare.jpg";

const airstrapz = [
  // Black
  {
    id: "airstrapz-for-airpods-black",
    name: "AirStrapz Dock \n for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airstrapz-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airstrapz-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airstrapz-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airstrapz-for-airpods-white",
      },
    ],
    selectedColor: "#202020",
    images: [airstrapzBlack1, airstrapzBlack2, airstrapzBlack3, airstrapzBlack4],
    description:
      "AirStrapz take the worry out of losing your AirPods. AirStrapz secure your AirPods around your neck and provides a quick and easy way for you to rest your AirPods when not in use. A polymer coated strap provides smooth contact with the back of your neck and quickly installs onto the AirPods. \n\n Ideal for daily commuters, runners, athletes and anyone else looking for an easy place to hang their AirPods when not in use.",
    note: "",
    banners: [airstrapzBannerBlack1, airstrapzBannerBlack2, airstrapzBannerBlack3],
    posters: [],
    relatedProducts: ["airstrapz-for-airpods-cobalt-blue", "airstrapz-for-airpods-pink", "airstrapz-for-airpods-white"],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
    },
  },

  // Cobalt blue
  {
    id: "airstrapz-for-airpods-cobalt-blue",
    name: "AirStrapz Dock \n for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airstrapz-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airstrapz-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airstrapz-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airstrapz-for-airpods-white",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [airstrapzCobaltBlue1, airstrapzCobaltBlue2, airstrapzCobaltBlue3, airstrapzCobaltBlue4],
    description:
      "AirStrapz take the worry out of losing your AirPods. AirStrapz secure your AirPods around your neck and provides a quick and easy way for you to rest your AirPods when not in use. A polymer coated strap provides smooth contact with the back of your neck and quickly installs onto the AirPods. \n\n Ideal for daily commuters, runners, athletes and anyone else looking for an easy place to hang their AirPods when not in use.",
    note: "",
    banners: [airstrapzBannerCobaltBlue1, airstrapzBannerCobaltBlue2, airstrapzBannerCobaltBlue3],
    posters: [],
    relatedProducts: ["airstrapz-for-airpods-black", "airstrapz-for-airpods-pink", "airstrapz-for-airpods-white"],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
    },
  },

  // Pink
  {
    id: "airstrapz-for-airpods-pink",
    name: "AirStrapz Dock \n for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airstrapz-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airstrapz-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airstrapz-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airstrapz-for-airpods-white",
      },
    ],
    selectedColor: "#FFB5CA",
    images: [airstrapzPink1, airstrapzPink2, airstrapzPink3, airstrapzPink4],
    description:
      "AirStrapz take the worry out of losing your AirPods. AirStrapz secure your AirPods around your neck and provides a quick and easy way for you to rest your AirPods when not in use. A polymer coated strap provides smooth contact with the back of your neck and quickly installs onto the AirPods. \n\n Ideal for daily commuters, runners, athletes and anyone else looking for an easy place to hang their AirPods when not in use.",
    note: "",
    banners: [airstrapzBannerPink1, airstrapzBannerPink2, airstrapzBannerPink3],
    posters: [],
    relatedProducts: [
      "airstrapz-for-airpods-black",
      "airstrapz-for-airpods-cobalt-blue",
      "airstrapz-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
    },
  },

  // White
  {
    id: "airstrapz-for-airpods-white",
    name: "AirStrapz Dock \n for AirPods",
    device: "AirPods",
    category: "AirPods Hooks",
    series: "",
    colors: [
      {
        color: "#202020",
        url: "airstrapz-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airstrapz-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airstrapz-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airstrapz-for-airpods-white",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [airstrapzWhite1, airstrapzWhite2, airstrapzWhite3, airstrapzWhite4],
    description:
      "AirStrapz take the worry out of losing your AirPods. AirStrapz secure your AirPods around your neck and provides a quick and easy way for you to rest your AirPods when not in use. A polymer coated strap provides smooth contact with the back of your neck and quickly installs onto the AirPods. \n\n Ideal for daily commuters, runners, athletes and anyone else looking for an easy place to hang their AirPods when not in use.",
    note: "",
    banners: [airstrapzBannerWhite1, airstrapzBannerWhite2, airstrapzBannerWhite3],
    posters: [],
    relatedProducts: ["airstrapz-for-airpods-black", "airstrapz-for-airpods-cobalt-blue", "airstrapz-for-airpods-pink"],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      next: {
        img: nextCategory,
        url: "/products/artisan-airpods-cleaning-kit",
      },
    },
  },
];

export default airstrapz;

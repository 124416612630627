/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import GeneralStore from "../../stores/GeneralStore";
import styles from "./Products.module.scss";
import topBanner from "../../assets/img/products/banners/top-banner.jpg";
import bottomBanner from "../../assets/img/products/banners/bottom-banner.jpg";
import ButtonBorder from "../../components/ButtonBorder";
import ShopProductItem from "../../components/ShopProductItem";
import ProductsFilterItem from "../../components/ProductsFilterItem";
import productsList from "../../stores/allProductsList";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Products = () => {
  const history = useHistory();
  const classes = useStyles();
  const generalStore = useContext(GeneralStore);
  const [devices] = useState([
    { id: 1, name: "AirPods" },
    { id: 3, name: "AirPods Pro" },
  ]);
  const [categories] = useState([
    { id: 1, name: "AirPods Essentials" },
    { id: 2, name: "AirPods Hooks" },
    { id: 3, name: "AirPods Cases" },
  ]);
  const [series] = useState([
    { id: 1, name: "Artisan Leather Series" },
    { id: 2, name: "EarBuddyz Series" },
    { id: 3, name: "EarBuddyz Ultra Series" },
    { id: 4, name: "Elevate Series" },
  ]);
  const [colors] = useState([
    { id: 1, name: "Black", color: "#202020" },
    { id: 2, name: "Cobalt Blue", color: "#4D5F7E" },
    { id: 3, name: "Brown", color: "#673E20" },
    { id: 4, name: "White", color: "#EFEFEF" },
    { id: 5, name: "Red", color: "#E14242" },
    { id: 6, name: "Pink", color: "#FFB5CA" },
    { id: 7, name: "Grey", color: "#B1BDC2" },
    { id: 8, name: "Yellow", color: "#E9D5A7" },
    { id: 9, name: "Lavender", color: "#BB9EFF" },
    { id: 10, name: "Diamond Blue", color: "#6CE8D8" },
    { id: 11, name: "Baby Blue", color: "#A2C3E9" },
  ]);

  const [filters, setFilters] = useState({
    device: "",
    category: "",
    series: "",
    color: "",
  });
  const [displayFormat, setDisplayFormat] = useState("dots");
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [sort, setSort] = useState("");
  const [showPerPage, setShowPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState({
    currentPage: 1,
    showingItemsFirst: 1,
    showingItemsLast: 1,
  });
  const [totalItems, setTotalItems] = useState(1);
  const [productsPaged, setProductsPaged] = useState({
    1: [],
  });

  const onPageNavigation = (selectedPage) => {
    if (selectedPage === 0) {
      return;
    }

    if (selectedPage > totalPages) {
      return;
    }

    const obj = {
      currentPage: selectedPage,
      showingItemsFirst: selectedPage * showPerPage - showPerPage + 1,
      showingItemsLast: selectedPage * showPerPage,
    };

    setCurrentPage(obj);
  };

  const onPagingLogic = (editableArr) => {
    const totPgs = Math.ceil(editableArr.length / showPerPage);
    setTotalPages(totPgs);
    setTotalItems(editableArr.length);

    let prodPage = {};
    if (editableArr.length < showPerPage) {
      prodPage = {
        1: editableArr,
      };
    } else {
      for (let index = 1; index <= totPgs; index++) {
        const first = 0;
        const last = showPerPage;
        const arr = editableArr.splice(first, last);
        prodPage[index] = arr;
      }
    }

    setProductsPaged(prodPage);
    onPageNavigation(1);
  };

  const onFilter = (type, data) => {
    setShowMobileFilter(false);

    const filterObj = { ...filters };
    filterObj[type] = data;
    setFilters(filterObj);

    let arr = [...productsList];
    if (type === "search") {
      const val = data.toLowerCase(); // for turning off case sensitive
      arr = productsList.filter((el) => el.name.toLowerCase().includes(val));
    }

    if (type !== "search" && type !== "sort" && data) {
      const searchedArr = [];
      arr.forEach((elem) => {
        if (type === "color") {
          if (elem.selectedColor.toLowerCase() === data.toLowerCase()) {
            searchedArr.push(elem);
          }
        } else if (elem[type] === data) {
          searchedArr.push(elem);
        }
      });

      arr = searchedArr;
    }

    if (type === "sort") {
      if (data === "name") {
        arr.sort(function (a, b) {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }

      if (data === "device") {
        arr.sort(function (a, b) {
          const nameA = a.device.toUpperCase(); // ignore upper and lowercase
          const nameB = b.device.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }
    }

    onPagingLogic([...arr]);
  };

  useEffect(() => {
    const arr = [...productsList];

    if (generalStore.wishlist.length > 0) {
      arr.forEach((elem) => {
        generalStore.wishlist.forEach((el) => {
          if (elem.id === el.id) {
            elem.wishlisted = true;
          } else {
            elem.wishlisted = false;
          }
        });
      });
    }

    onPagingLogic([...productsList]);
  }, [showPerPage]);

  useEffect(() => {
    if (history.location.hash) {
      const fil = history.location.hash.slice(1);
      onFilter("device", fil);
    }
  }, [JSON.stringify(history.location)]);

  useEffect(() => {
    generalStore.setShowWhiteNavbar(false);
  }, []);

  return (
    <div className={styles.shopWrapper}>
      <section className={styles.topBanner}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="section-title text-left">Artisan Series Collection</h1>
            </div>
            <div className="col-12 position-relative">
              <img src={topBanner} alt="top banner" />

              <div className={styles.topBannerDiv}>
                <h1 className={cx("section-title")}>Handcrafted Italian Leather</h1>

                <ButtonBorder
                  text="Explore Now"
                  showBorder
                  showWhite
                  colorWhite
                  icon="arrow"
                  onClick={() => {
                    history.push("products/elevate-series-airpods-pro-case-black");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.productsSection}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3 d-none d-lg-block">
              <div className="row">
                <div className="col-12 mb-5">
                  <ProductsFilterItem
                    title="Device"
                    categories={devices}
                    selectedCategory={filters?.device || null}
                    onSelectCategory={(value) => {
                      onFilter("device", filters?.device ? "" : value);
                    }}
                  />
                </div>

                <div className="col-12 mb-5">
                  <ProductsFilterItem
                    title="Categories"
                    categories={categories}
                    selectedCategory={filters?.category || null}
                    onSelectCategory={(value) => {
                      onFilter("category", filters?.category ? "" : value);
                    }}
                  />
                </div>

                <div className="col-12 mb-5">
                  <ProductsFilterItem
                    title="Series"
                    categories={series}
                    selectedCategory={filters?.series || null}
                    onSelectCategory={(value) => {
                      onFilter("series", filters?.series ? "" : value);
                    }}
                  />
                </div>

                <div className="col-12 mb-5">
                  <ProductsFilterItem
                    title="Colors"
                    categories={colors}
                    selectedCategory={filters?.color || null}
                    onSelectCategory={(value) => {
                      onFilter("color", filters?.color ? "" : value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-9">
              <div className="row mb-4">
                <div className="col-12 col-lg-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={styles.displayingFormatDiv}>
                      <div
                        className={cx(styles.div, displayFormat === "dots" && styles.active)}
                        onClick={() => {
                          setDisplayFormat("dots");
                        }}
                      >
                        <Icon>apps</Icon>
                      </div>
                      <div
                        className={cx(styles.div, displayFormat === "list" && styles.active)}
                        onClick={() => {
                          setDisplayFormat("list");
                        }}
                      >
                        <Icon>format_list_bulleted</Icon>
                      </div>
                    </div>

                    <div className={styles.displayingFormatDiv}>
                      <div
                        className={cx(styles.div, styles.active)}
                        onClick={() => {
                          setShowMobileFilter(!showMobileFilter);
                        }}
                      >
                        <Icon>{showMobileFilter ? "close" : "filter_list"}</Icon>
                      </div>
                    </div>

                    {showMobileFilter && (
                      <div className={styles.mobileFilterDiv}>
                        <div className="row">
                          <div className="col-12 mb-5">
                            <ProductsFilterItem
                              title="Device"
                              categories={devices}
                              selectedCategory={filters?.device || null}
                              onSelectCategory={(value) => {
                                onFilter("device", filters?.device ? "" : value);
                              }}
                            />
                          </div>

                          <div className="col-12 mb-5">
                            <ProductsFilterItem
                              title="Categories"
                              categories={categories}
                              selectedCategory={filters?.category || null}
                              onSelectCategory={(value) => {
                                onFilter("category", filters?.category ? "" : value);
                              }}
                            />
                          </div>

                          <div className="col-12 mb-5">
                            <ProductsFilterItem
                              title="Series"
                              categories={series}
                              selectedCategory={filters?.series || null}
                              onSelectCategory={(value) => {
                                onFilter("series", filters?.series ? "" : value);
                              }}
                            />
                          </div>

                          <div className="col-12 mb-5">
                            <ProductsFilterItem
                              title="Colors"
                              categories={colors}
                              selectedCategory={filters?.color || null}
                              onSelectCategory={(value) => {
                                onFilter("color", filters?.color ? "" : value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 col-lg-4">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="sortByLabel">Sort by</InputLabel>
                    <Select
                      labelId="sortByLabel"
                      id="sortBy-select"
                      value={sort}
                      onChange={(event) => {
                        setSort(event.target.value);
                        onFilter("sort", event.target.value);
                      }}
                    >
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="device">Device</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col-12 col-lg-4">
                  <p className={styles.showingInfo}>
                    Showing {currentPage.showingItemsFirst} -{" "}
                    {totalItems > currentPage.showingItemsLast ? currentPage.showingItemsLast : totalItems} of{" "}
                    {totalItems} results
                  </p>
                </div>
              </div>

              <div className="row">
                {productsPaged[currentPage.currentPage] &&
                  productsPaged[currentPage.currentPage].map((product) => (
                    <div className={displayFormat === "dots" ? "col-12 col-lg-4 mb-3" : "col-12 mb-3"} key={product.id}>
                      <ShopProductItem
                        product={product}
                        displayFormat={displayFormat}
                        onAddToWishlist={(product) => {
                          const arr = {
                            ...productsPaged,
                          };

                          arr[currentPage.currentPage].map((el) => {
                            if (el.id === product.id) {
                              if (el.wishlisted) {
                                el.wishlisted = false;
                              } else {
                                el.wishlisted = true;
                              }
                            }

                            return el;
                          });

                          let wishArr = [...generalStore.wishlist];
                          const wFiltArr = wishArr.filter((el) => el.id === product.id);
                          if (wFiltArr.length === 0) {
                            wishArr.push(product);
                          } else {
                            wishArr = wishArr.filter((el) => el.id !== product.id);
                          }

                          generalStore.setWishlist(wishArr);

                          setProductsPaged(arr);
                        }}
                      />
                    </div>
                  ))}
              </div>

              <div className={cx(styles.mobilePagination, "row mb-4")}>
                <div className="col-12 col-sm-8">
                  <Pagination
                    page={currentPage.currentPage || 1}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={(event, value) => {
                      onPageNavigation(value);
                      window.scrollTo(0, 700);
                    }}
                  />
                </div>

                <div className="col-12 col-sm-4">
                  <div className="d-flex justify-content-end">
                    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: "120px" }}>
                      <Select
                        labelId="sortByLabel"
                        id="sortBy-select"
                        value={showPerPage}
                        onChange={(event) => {
                          setShowPerPage(event.target.value);
                          window.scrollTo(0, 700);
                        }}
                      >
                        <MenuItem value="3">Showing 3</MenuItem>
                        <MenuItem value="6">Showing 6</MenuItem>
                        <MenuItem value="9">Showing 9</MenuItem>
                        <MenuItem value="12">Showing 12</MenuItem>
                        <MenuItem value="15">Showing 15</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.bottomBanner}>
        <div className="position-relative">
          <img src={bottomBanner} alt="bottom banner" />

          <div className={styles.bottomBannerDiv}>
            <h1 className={cx("section-title")}>Elevate Series for AirPods Gen 1/2</h1>

            <ButtonBorder
              text="Explore Now"
              showBorder
              showWhite
              colorWhite
              icon="arrow"
              onClick={() => {
                history.push("products/elevate-series-for-airpods-gen-1-2-red");
              }}
            />
          </div>
        </div>
      </section>

      <section className={styles.bottomInfoSection}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-5 offset-md-1">
              <h1 className="section-title">Best AirPods Accessories there is.</h1>
            </div>

            <div className="col-12 col-md-5">
              <p>
                We've all been there,.. the never-ever land of constantly pushing in, twisting and relentless
                readjusting of our Apple AirPods & Earpods.
                <br />
                <br />
                EarBuddyz AirPods ear hooks eliminate the fuss and hassle of constantly adjusting your Apple earphones
                and provide an ultra secure listening experience.
                <br />
                <br />
                Engineered from high performance elastomers, EarBuddyz AirPods Hooks comfortably grip your ear and
                provide additional support by conforming to the groove of your inner ear. Experience twice the comfort
                and secure fit compared to using Apple earphones alone, Buddy Up!
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;

import aircare from "./products/AirCare";
import hybridshell from "./products/HybridShell";
import hybridShellKeychain from "./products/HybridShellKeychain";
import artisan12 from "./products/Artisan1-2";
import artisanPro from "./products/ArtisanPro";
import earbuddyz12 from "./products/EarBuddyz1-2";
import earbuddyzPro from "./products/EarBuddyzPro";
import earbuddyzUltra12 from "./products/EarBuddyzUltra1-2";
import earbuddyzUltraPro from "./products/EarBuddyzUltraPro";
import elevate12 from "./products/Elevate1-2";
import elevateSwarovski from "./products/ElevateSwarowski";
import elevatePro from "./products/ElevatePro";
import airdockz from "./products/AirDockz";
import airlockz from "./products/AirLockz";
import airstrapz from "./products/AirStrapz";

const allProductsList = [
  ...hybridshell,
  ...hybridShellKeychain,
  ...artisan12,
  ...artisanPro,
  ...earbuddyz12,
  ...earbuddyzPro,
  ...earbuddyzUltra12,
  ...earbuddyzUltraPro,
  ...elevate12,
  ...elevateSwarovski,
  ...elevatePro,
  ...airdockz,
  ...airlockz,
  ...airstrapz,
  ...aircare,
];

export default allProductsList;

// Black
import airlockzBlack1 from "../../assets/img/products/products/Single Product/AirLockz/Black/airlockz-blk-1.png";
import airlockzBlack2 from "../../assets/img/products/products/Single Product/AirLockz/Black/airlockz-blk-2.png";
import airlockzBannerBlack1 from "../../assets/img/products/products/Single Product/AirLockz/Black/airlockz-blk-banner-1.jpg";
import airlockzBannerBlack2 from "../../assets/img/products/products/Single Product/AirLockz/Black/airlockz-blk-banner-2.jpg";
import airlockzBannerBlack3 from "../../assets/img/products/products/Single Product/AirLockz/Black/airlockz-blk-banner-3.jpg";

// Cobalt Blue
import airlockzCobaltBlue1 from "../../assets/img/products/products/Single Product/AirLockz/Cobalt Blue/airlockz-cbb-1.png";
import airlockzCobaltBlue2 from "../../assets/img/products/products/Single Product/AirLockz/Cobalt Blue/airlockz-cbb-2.png";
import airlockzBannerCobaltBlue1 from "../../assets/img/products/products/Single Product/AirLockz/Cobalt Blue/airlockz-cbb-banner-1.jpg";
import airlockzBannerCobaltBlue2 from "../../assets/img/products/products/Single Product/AirLockz/Cobalt Blue/airlockz-cbb-banner-2.jpg";
import airlockzBannerCobaltBlue3 from "../../assets/img/products/products/Single Product/AirLockz/Cobalt Blue/airlockz-cbb-banner-3.jpg";

// Pink
import airlockzPink1 from "../../assets/img/products/products/Single Product/AirLockz/Blush Pink/airlockz-bpk-1.png";
import airlockzPink2 from "../../assets/img/products/products/Single Product/AirLockz/Blush Pink/airlockz-bpk-2.png";
import airlockzBannerPink1 from "../../assets/img/products/products/Single Product/AirLockz/Blush Pink/airlockz-bpk-banner-1.jpg";
import airlockzBannerPink2 from "../../assets/img/products/products/Single Product/AirLockz/Blush Pink/airlockz-bpk-banner-2.jpg";
import airlockzBannerPink3 from "../../assets/img/products/products/Single Product/AirLockz/Blush Pink/airlockz-bpk-banner-3.jpg";

// White
import airlockzWhite1 from "../../assets/img/products/products/Single Product/AirLockz/White/airlockz-wht-1.png";
import airlockzWhite2 from "../../assets/img/products/products/Single Product/AirLockz/White/airlockz-wht-2.png";
import airlockzBannerWhite1 from "../../assets/img/products/products/Single Product/AirLockz/White/airlockz-wht-banner-1.jpg";
import airlockzBannerWhite2 from "../../assets/img/products/products/Single Product/AirLockz/White/airlockz-wht-banner-2.jpg";
import airlockzBannerWhite3 from "../../assets/img/products/products/Single Product/AirLockz/White/airlockz-wht-banner-3.jpg";

import bottomBannerBlack from "../../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import prevCategory from "../../assets/img/products/products/Prev Next/prev-airdockz.jpg";
import nextCategory from "../../assets/img/products/products/Prev Next/next-airstrapz.jpg";

const airlockz = [
  // Black
  {
    id: "airlockz-watch-dock-for-airpods-black",
    name: "AirLockz Watch \n dock for AirPods",
    device: "AirPods",
    category: "AirPods Essentials",
    series: "Elevate Series",
    colors: [
      {
        color: "#202020",
        url: "airlockz-watch-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airlockz-watch-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airlockz-watch-dock-for-airpods-white",
      },
    ],
    selectedColor: "#202020",
    images: [airlockzBlack1, airlockzBlack2],
    description:
      "AirLockz keep your AirPods in a convenient spot for quick access. Made to fit most Apple Watch straps, the AirLockz slide onto the strap and securely lock the AirPods in when not in use. \n\n Perfect for an easy on and off when on the go.",
    note: "",
    banners: [airlockzBannerBlack1, airlockzBannerBlack2, airlockzBannerBlack3],
    posters: [],
    relatedProducts: [
      "airlockz-watch-dock-for-airpods-cobalt-blue",
      "airlockz-watch-dock-for-airpods-pink",
      "airlockz-watch-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
      next: {
        img: nextCategory,
        url: "/products/airstrapz-for-airpods-black",
      },
    },
  },

  // Cobalt blue
  {
    id: "airlockz-watch-dock-for-airpods-cobalt-blue",
    name: "AirLockz Watch \n dock for AirPods",
    device: "AirPods",
    category: "AirPods Essentials",
    series: "Elevate Series",
    colors: [
      {
        color: "#202020",
        url: "airlockz-watch-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airlockz-watch-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airlockz-watch-dock-for-airpods-white",
      },
    ],
    selectedColor: "#4D5F7E",
    images: [airlockzCobaltBlue1, airlockzCobaltBlue2],
    description:
      "AirLockz keep your AirPods in a convenient spot for quick access. Made to fit most Apple Watch straps, the AirLockz slide onto the strap and securely lock the AirPods in when not in use. \n\n Perfect for an easy on and off when on the go.",
    note: "",
    banners: [airlockzBannerCobaltBlue1, airlockzBannerCobaltBlue2, airlockzBannerCobaltBlue3],
    posters: [],
    relatedProducts: [
      "airlockz-watch-dock-for-airpods-black",
      "airlockz-watch-dock-for-airpods-pink",
      "airlockz-watch-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
      next: {
        img: nextCategory,
        url: "/products/airstrapz-for-airpods-black",
      },
    },
  },

  // Pink
  {
    id: "airlockz-watch-dock-for-airpods-pink",
    name: "AirLockz Watch \n dock for AirPods",
    device: "AirPods",
    category: "AirPods Essentials",
    series: "Elevate Series",
    colors: [
      {
        color: "#202020",
        url: "airlockz-watch-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airlockz-watch-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airlockz-watch-dock-for-airpods-white",
      },
    ],
    selectedColor: "#FFB5CA",
    images: [airlockzPink1, airlockzPink2],
    description:
      "AirLockz keep your AirPods in a convenient spot for quick access. Made to fit most Apple Watch straps, the AirLockz slide onto the strap and securely lock the AirPods in when not in use. \n\n Perfect for an easy on and off when on the go.",
    note: "",
    banners: [airlockzBannerPink1, airlockzBannerPink2, airlockzBannerPink3],
    posters: [],
    relatedProducts: [
      "airlockz-watch-dock-for-airpods-black",
      "airlockz-watch-dock-for-airpods-cobalt-blue",
      "airlockz-watch-dock-for-airpods-white",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
      next: {
        img: nextCategory,
        url: "/products/airstrapz-for-airpods-black",
      },
    },
  },

  // White
  {
    id: "airlockz-watch-dock-for-airpods-white",
    name: "AirLockz Watch \n dock for AirPods",
    device: "AirPods",
    category: "AirPods Essentials",
    series: "Elevate Series",
    colors: [
      {
        color: "#202020",
        url: "airlockz-watch-dock-for-airpods-black",
      },
      {
        color: "#4D5F7E",
        url: "airlockz-watch-dock-for-airpods-cobalt-blue",
      },
      {
        color: "#FFB5CA",
        url: "airlockz-watch-dock-for-airpods-pink",
      },
      {
        color: "#EFEFEF",
        url: "airlockz-watch-dock-for-airpods-white",
      },
    ],
    selectedColor: "#EFEFEF",
    images: [airlockzWhite1, airlockzWhite2],
    description:
      "AirLockz keep your AirPods in a convenient spot for quick access. Made to fit most Apple Watch straps, the AirLockz slide onto the strap and securely lock the AirPods in when not in use. \n\n Perfect for an easy on and off when on the go.",
    note: "",
    banners: [airlockzBannerWhite1, airlockzBannerWhite2, airlockzBannerWhite3],
    posters: [],
    relatedProducts: [
      "airlockz-watch-dock-for-airpods-black",
      "airlockz-watch-dock-for-airpods-cobalt-blue",
      "airlockz-watch-dock-for-airpods-pink",
    ],
    bottomBanner: { img: bottomBannerBlack, url: "/products/earbuddyz-for-airpods-pro-black" },
    relatedCategory: {
      prev: {
        img: prevCategory,
        url: "/products/airdockz-dock-for-airpods-black",
      },
      next: {
        img: nextCategory,
        url: "/products/airstrapz-for-airpods-black",
      },
    },
  },
];

export default airlockz;

import aircare from "../assets/img/products/products/Banners Random/Banner - AirCare Cleaning Kit.jpg";

import airdockzBlack from "../assets/img/products/products/Banners Random/Banner - AirDockz - Black.jpg";
import airdockzCobalt from "../assets/img/products/products/Banners Random/Banner - AirDockz - Cobalt Blue.jpg";
import airdockzPink from "../assets/img/products/products/Banners Random/Banner - AirDockz - Pink.jpg";
import airdockzWhite from "../assets/img/products/products/Banners Random/Banner - AirDockz - White.jpg";

import airlockzBlack from "../assets/img/products/products/Banners Random/Banner - AirLockz - Black.jpg";
import airlockzCobalt from "../assets/img/products/products/Banners Random/Banner - AirLockz - Cobalt Blue.jpg";
import airlockzPink from "../assets/img/products/products/Banners Random/Banner - AirLockz - Pink.jpg";
import airlockzWhite from "../assets/img/products/products/Banners Random/Banner - AirLockz - White.jpg";

import airstrapzBlack from "../assets/img/products/products/Banners Random/Banner - AirStrapz - Black.jpg";
import airstrapzCobalt from "../assets/img/products/products/Banners Random/Banner - AirStrapz - Cobalt Blue.jpg";
import airstrapzPink from "../assets/img/products/products/Banners Random/Banner - AirStrapz - Pink.jpg";
import airstrapzWhite from "../assets/img/products/products/Banners Random/Banner - AirStrapz - White.jpg";

import artisanBlack from "../assets/img/products/products/Banners Random/Banner - Artisan - Black.jpg";
import artisanBrown from "../assets/img/products/products/Banners Random/Banner - Artisan - Brown.jpg";
import artisanGrey from "../assets/img/products/products/Banners Random/Banner - Artisan - Grey.jpg";
import artisanRed from "../assets/img/products/products/Banners Random/Banner - Artisan - Red.jpg";

import artisanProBlack from "../assets/img/products/products/Banners Random/Banner - Artisan Pro - Black.jpg";
import artisanProBrown from "../assets/img/products/products/Banners Random/Banner - Artisan Pro - Brown.jpg";
import artisanProGrey from "../assets/img/products/products/Banners Random/Banner - Artisan Pro - Grey.jpg";
import artisanProRed from "../assets/img/products/products/Banners Random/Banner - Artisan Pro - Red.jpg";

import earbuddyzBlack from "../assets/img/products/products/Banners Random/Banner - EarBuddyz - Black.jpg";
import earbuddyzWhite from "../assets/img/products/products/Banners Random/Banner - EarBuddyz - White.jpg";

import earbuddyzProBlack from "../assets/img/products/products/Banners Random/Banner - EarBuddyz PRO - Black.jpg";
import earbuddyzProWhite from "../assets/img/products/products/Banners Random/Banner - EarBuddyz PRO - White.jpg";

import earbuddyzUltraBlack from "../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA - Black.jpg";
import earbuddyzUltraWhite from "../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA - White.jpg";

import earbuddyzUltraPro from "../assets/img/products/products/Banners Random/Banner - EarBuddyz ULTRA PRO.jpg";

import elevateBabyBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Baby Blue-1.jpg";
import elevateBlack from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Black-1.jpg";
import elevateCobaltBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Cobalt Blue-1.jpg";
import elevateDiamondBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Diamond Blue-1.jpg";
import elevateGrey from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Grey-1.jpg";
import elevateLavender from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Lavender-1.jpg";
import elevatePink from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Pink-1.jpg";
import elevateRed from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Red-1.jpg";
import elevateWhite from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - White-1.jpg";
import elevateYellow from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Yellow-1.jpg";

import elevateProBabyBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Baby Blue.jpg";
import elevateProBlack from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Black.jpg";
import elevateProCobaltBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Cobalt Blue.jpg";
import elevateProDiamondBlue from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Diamond Blue.jpg";
import elevateProGrey from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Grey.jpg";
import elevateProLavender from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Lavender.jpg";
import elevateProPink from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Pink.jpg";
import elevateProRed from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Red.jpg";
import elevateProWhite from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - White.jpg";
import elevateProYellow from "../assets/img/products/products/Banners Random/Banner - Elevate Pro - Yellow.jpg";

import elevateSwarovski from "../assets/img/products/products/Banners Random/Banner - Elevate x Swarovski.jpg";

import hybridshellBlack from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Black.jpg";
import hybridshellCobalt from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Cobalt Blue.jpg";
import hybridshellPink from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Pink.jpg";

import hybridshellKeychainBlack from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Keychain - Black.jpg";
import hybridshellKeychainCobalt from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Keychain - Cobalt Blue.jpg";
import hybridshellKeychainPink from "../assets/img/products/products/Banners Random/Banner - Hybridshell - Keychain - Pink.jpg";

const randomBanners = [
  { img: aircare, url: "/products/artisan-airpods-cleaning-kit", button: "black" },

  { img: airdockzBlack, url: "/products/airdockz-dock-for-airpods-black", button: "black" },
  { img: airdockzCobalt, url: "/products/airdockz-dock-for-airpods-cobalt-blue", button: "black" },
  { img: airdockzPink, url: "/products/airdockz-dock-for-airpods-pink", button: "black" },
  { img: airdockzWhite, url: "/products/airdockz-dock-for-airpods-white", button: "black" },

  { img: airlockzBlack, url: "/products/airlockz-watch-dock-for-airpods-black", button: "black" },
  { img: airlockzCobalt, url: "/products/airlockz-watch-dock-for-airpods-cobalt-blue", button: "black" },
  { img: airlockzPink, url: "/products/airlockz-watch-dock-for-airpods-pink", button: "black" },
  { img: airlockzWhite, url: "/products/airlockz-watch-dock-for-airpods-white", button: "black" },

  { img: airstrapzBlack, url: "/products/airstrapz-for-airpods-black", button: "black" },
  { img: airstrapzCobalt, url: "/products/airstrapz-for-airpods-cobalt-blue", button: "black" },
  { img: airstrapzPink, url: "/products/airstrapz-for-airpods-pink", button: "black" },
  { img: airstrapzWhite, url: "/products/airstrapz-for-airpods-white", button: "black" },

  { img: artisanBlack, url: "/products/artisan-series-airpods-1-2-case-black", button: "white" },
  { img: artisanBrown, url: "/products/artisan-series-airpods-1-2-case-brown", button: "white" },
  { img: artisanGrey, url: "/products/artisan-series-airpods-1-2-case-grey", button: "white" },
  { img: artisanRed, url: "/products/artisan-series-airpods-1-2-case-red", button: "white" },

  { img: artisanProBlack, url: "/products/artisan-series-airpods-pro-case-black", button: "white" },
  { img: artisanProBrown, url: "/products/artisan-series-airpods-pro-case-brown", button: "white" },
  { img: artisanProGrey, url: "/products/artisan-series-airpods-pro-case-grey", button: "white" },
  { img: artisanProRed, url: "/products/artisan-series-airpods-pro-case-red", button: "white" },

  { img: earbuddyzBlack, url: "/products/earbuddyz-for-airpods-gen-1-2-black", button: "white" },
  { img: earbuddyzWhite, url: "/products/earbuddyz-for-airpods-gen-1-2-white", button: "black" },

  { img: earbuddyzProBlack, url: "/products/earbuddyz-for-airpods-pro-black", button: "white" },
  { img: earbuddyzProWhite, url: "/products/earbuddyz-for-airpods-pro-white", button: "black" },

  { img: earbuddyzUltraBlack, url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-black", button: "white" },
  { img: earbuddyzUltraWhite, url: "/products/earbuddyz-ultra-for-airpods-gen-1-2-white", button: "black" },

  { img: earbuddyzUltraPro, url: "/products/earbuddyz-ultra-for-airpods-pro", button: "black" },

  { img: elevateBabyBlue, url: "/products/elevate-series-for-airpods-gen-1-2-baby-blue", button: "white" },
  { img: elevateBlack, url: "/products/elevate-series-for-airpods-gen-1-2-black", button: "white" },
  { img: elevateCobaltBlue, url: "/products/elevate-series-for-airpods-gen-1-2-cobalt-blue", button: "white" },
  { img: elevateDiamondBlue, url: "/products/elevate-series-for-airpods-gen-1-2-diamond-blue", button: "black" },
  { img: elevateGrey, url: "/products/elevate-series-for-airpods-gen-1-2-grey", button: "white" },
  { img: elevateLavender, url: "/products/elevate-series-for-airpods-gen-1-2-lavender", button: "white" },
  { img: elevatePink, url: "/products/elevate-series-for-airpods-gen-1-2-pink", button: "black" },
  { img: elevateRed, url: "/products/elevate-series-for-airpods-gen-1-2-red", button: "white" },
  { img: elevateWhite, url: "/products/elevate-series-for-airpods-gen-1-2-white", button: "black" },
  { img: elevateYellow, url: "/products/elevate-series-for-airpods-gen-1-2-yellow", button: "black" },

  { img: elevateProBabyBlue, url: "/products/elevate-series-airpods-pro-case-baby-blue", button: "white" },
  { img: elevateProBlack, url: "/products/elevate-series-airpods-pro-case-black", button: "white" },
  { img: elevateProCobaltBlue, url: "/products/elevate-series-airpods-pro-case-cobalt-blue", button: "white" },
  { img: elevateProDiamondBlue, url: "/products/elevate-series-airpods-pro-case-diamond-blue", button: "black" },
  { img: elevateProGrey, url: "/products/elevate-series-airpods-pro-case-grey", button: "white" },
  { img: elevateProLavender, url: "/products/elevate-series-airpods-pro-case-lavender", button: "white" },
  { img: elevateProPink, url: "/products/elevate-series-airpods-pro-case-pink", button: "black" },
  { img: elevateProRed, url: "/products/elevate-series-airpods-pro-case-red", button: "white" },
  { img: elevateProWhite, url: "/products/elevate-series-airpods-pro-case-white", button: "black" },
  { img: elevateProYellow, url: "/products/elevate-series-airpods-pro-case-yellow", button: "black" },

  { img: elevateSwarovski, url: "/products/elevate-swarovski-for-airpods-pro", button: "black" },

  { img: hybridshellBlack, url: "/products/hybridshell-series-airpods-pro-case-black", button: "white" },
  { img: hybridshellCobalt, url: "/products/hybridshell-series-airpods-pro-case-cobalt-blue", button: "white" },
  { img: hybridshellPink, url: "/products/hybridshell-series-airpods-pro-case-pink", button: "white" },

  { img: hybridshellKeychainBlack, url: "/products/hybridshell-series-airpods-pro-case-black", button: "white" },
  { img: hybridshellKeychainCobalt, url: "/products/hybridshell-series-airpods-pro-case-cobalt-blue", button: "white" },
  { img: hybridshellKeychainPink, url: "/products/hybridshell-series-airpods-pro-case-pink", button: "white" },
];

export default randomBanners;
